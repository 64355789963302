.ads__text {
    align-items: center;
    color: var(--color-secondary-2);
    display: flex;
    flex-flow: column nowrap;
    font-size: 10px;
    height: 20px;
    justify-content: center
}

[class*=banx-fsnews_responsive] .ads__text,
[class*=banx-responsive] .ads__text {
    height: 10px;
    min-height: 10px
}

.adstextvpad .ads__text {
    flex: 0 0 90px;
    height: 10px;
    margin-left: -38px;
    transform: rotate(-90deg)
}

.bonus-offers-bookmark .ads__text,
.ifmenu-wrapper .ads__text {
    bottom: 2px;
    position: absolute;
    right: 10px
}

.adscontent {
    height: 100px;
}

.adsenvelopeCont {
    display: flex;
    flex-wrap: wrap
}

@media only screen and (max-width:639px) {
    .pid_8 .fsNewsArticle__content .adsenvelopeCont {
        position: relative;
        top: -8px
    }
}

.adsenvelopeCont__interscroller {
    display: flex;
    flex-direction: column;
    margin: 0 -12px;
    width: calc(100% + 24px)
}

.pid_8 .adsenvelopeCont__interscroller {
    flex-direction: column-reverse
}

.adsenvelopeCont__interscroller .adsenvelope {
    height: 70vh;
    padding-bottom: 0;
    width: 100% !important
}

body:not(.pid_8) .adsenvelopeCont__interscroller .adsenvelope {
    margin-bottom: 0 !important
}

.adsenvelopeCont__interscroller .adsenvelope[style*="display: block;"]+.adsenvelopeText {
    display: block
}

.adsenvelopeCont__interscroller .adsenvelope>div {
    clip: rect(0, auto, auto, 0);
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    overflow: hidden;
    position: absolute;
    width: 100px;
}

.adsenvelopeCont__interscroller .adscontent {
    position: fixed;
    top: 0;
    -webkit-transform: translateZ(0);
    width: 100px;
}

.adsenvelopeCont__interscroller .adscontent iframe {
    height: 100% !important;
    width: 100% !important
}

@media only screen and (max-width:639px) {
    .adsenvelopeCont__interscroller .adsenvelopeText {
        color: var(--color-secondary-2);
        font-size: 10px;
        height: 20px;
        text-align: center
    }
    body:not(.pid_8) .adsenvelopeCont__interscroller .adsenvelopeText {
        margin-bottom: 8px
    }
    .adsenvelopeCont__interscroller .ads__text {
        display: none
    }
}

.adsenvelopeCont__square {
    margin: 0 -12px;
    width: calc(100% + 24px)
}

.adsenvelopeCont__square .adsenvelope {
    width: 100% !important
}

body:not(.pid_8) .adsenvelopeCont__square .adsenvelope {
    padding-bottom: 0
}

.adsenvelopeCont__square .adsenvelope>div {
    height: auto !important
}

.adsenvelopeCont__square .adscontent {
    overflow: hidden;
    padding-top: min(480px, 100%);
    position: relative;
    width: 100px;
}

.adsenvelopeCont__square .adscontent iframe {
    border: none;
    bottom: 0;
    height: 100% !important;
    left: 0;
    margin: 0 auto;
    max-width: 480px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important
}

.adsenvelopeText {
    display: none
}

.adsenvelope {
    margin: 0 auto 12px 8px;
    padding-bottom: 20px;
    position: relative
}

.adsenvelopeCont .adsenvelope {
    margin: 0 auto 8px
}

.container__bannerZone .adsenvelope,
.livescore .adsenvelope.banx-content_bottom {
    margin: 0 auto 12px
}

.pid_1:not(.tv-program-page) #rccontent,
.pid_7 #rccontent {
    height: calc(100% - 72px);
    position: relative;
    top: 14px;
    width: 100px;
}

.myfs.pid_1:not(.tv-program-page) #rccontent,
.myfs.pid_7 #rccontent,
.pid_1:not(.tv-program-page).fsNewsPage #rccontent,
.pid_7.fsNewsPage #rccontent {
    top: 0
}

.ban_envelope {
    margin: 0 auto;
    position: relative
}

.ban_envelope div,
.ban_envelope iframe {
    height: 100%;
    width: 100px;
}

.bannerEnvelope {
    margin-top: 10px
}

.crttextpad {
    margin-top: 0
}

.detailbody .bannerEnvelope {
    margin-top: 24px
}

.detailbody .crttextpad {
    margin-top: 26px
}

.adstextpad>div,
.crttextpad>div {
    position: relative
}

.container__liveTableWrapper .adstextpad,
.container__liveTableWrapper .crttextpad {
    margin: 28px auto 0
}

@media only screen and (max-width:799px) {
    .container__liveTableWrapper .adstextpad,
    .container__liveTableWrapper .crttextpad {
        margin: 12px auto 0
    }
}

.adstextvpad {
    float: left;
    margin-left: 0;
    padding-bottom: 0;
    padding-right: 12px !important
}

.adstextvpad,
.adstextvpad>div {
    display: flex;
    flex-flow: row nowrap
}

.adstextvpad>div {
    align-items: center;
    position: relative
}

.adsclick {
    background: #fff;
    cursor: pointer;
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: var(--zIndex-banner)
}

.adsclick img {
    display: none
}

.adsinline {
    text-align: center
}

.adsinline-left,
.adsinline-right {
    position: absolute;
    top: 0;
    width: 120px
}

.adsinline-left {
    left: 0
}

.adsinline-right {
    right: 0
}

.adsenvelope[class*=" banx-top"] .ads__text,
.adsenvelope[class^=banx-top] .ads__text {
    color: var(--color-white)
}

#detail .adsenvelope,
#detail .ban_envelope {
    margin: 0 auto;
    padding-top: 0
}

#lc .ban_envelope {
    margin: 0 auto
}

.myfs #lc .adsenvelope {
    background-color: var(--color-background-8);
    padding: 10px 10px 20px
}

.banx-traffic_code {
    margin-left: 0;
    width: 760px
}

.banx-detail_strip_soccer {
    padding-top: 0 !important
}

.adsgraphhori {
    height: 10px !important;
    left: 0;
    margin-top: 3px;
    position: absolute;
    text-align: center;
    width: 100px;
}

.adsgraphhori a {
    display: block;
    height: 100%;
    width: 100px;
}

.adsghori {
    background: url(http://www.flashscore.com/res/_fs/build/icon-adverth.46e572e.png) no-repeat 50% 0;
    background-size: 61px;
    height: 100%;
    width: 100px;
}

.adsgraphvert {
    right: 0;
    top: 50%;
    transform: rotate(-90deg) translate(50%, -100%);
    transform-origin: top right
}

.adsgraphvert,
.adsgraphvert-small {
    height: 10px !important;
    position: absolute
}

.adsgraphvert-small {
    background: url(http://www.flashscore.com/res/_fs/build/icon-adverth-right.e938043.png) no-repeat 100% 0;
    background-size: 61px;
    bottom: -12px;
    right: 20px
}

.adsgraphvert a,
.adsgraphvert-small {
    display: block;
    height: 100%;
    width: 100px;
}

.adsgvert {
    background: url(http://www.flashscore.com/res/_fs/build/icon-adverth.46e572e.png) no-repeat 50% 0;
    background-size: 61px;
    height: 100%;
    width: 100px;
}

.ath-cs,
.ath-lt,
.ath-pl,
.ath-sk,
.ath-sr,
.atv-cs,
.atv-lt,
.atv-pl,
.atv-sk,
.atv-sr {
    background-position: 50% -17px
}

.ath-de,
.atv-de {
    background-position: 50% -37px
}

.ath-it,
.atv-it {
    background-position: 50% -55px
}

.ath-da,
.atv-da {
    background-position: 50% -72px
}

.ath-ro,
.atv-ro {
    background-position: 50% -91px
}

.ath-sv,
.ath-tr,
.atv-sv,
.atv-tr {
    background-position: 50% -108px
}

.ath-ru,
.atv-ru {
    background-position: 50% -128px
}

.ath-es,
.atv-es {
    background-position: 50% -144px
}

.ath-hr,
.atv-hr {
    background-position: 50% -163px
}

.ath-hu,
.atv-hu {
    background-position: 50% -180px
}

.ath-fr,
.atv-fr {
    background-position: 50% -198px
}

.ath-fi,
.atv-fi {
    background-position: 50% -217px
}

.ath-el,
.atv-el {
    background-position: 50% -234px
}

.ath-pt,
.ath-pt-br,
.atv-pt,
.atv-pt-br {
    background-position: 50% -253px
}

.ath-nl,
.atv-nl {
    background-position: 50% -269px
}

.ath-et,
.atv-et {
    background-position: 50% -287px
}

.ath-no,
.atv-no {
    background-position: 50% -306px
}

.ath-sl,
.atv-sl {
    background-position: 50% -325px
}

.ath-en-uk,
.atv-en-uk {
    background-position: 50% -342px
}

.ath-r-de {
    background-position: 100% -37px
}

.ath-r-it {
    background-position: 100% -55px
}

.ath-r-da {
    background-position: 100% -72px
}

.ath-r-ro {
    background-position: 100% -91px
}

.ath-r-sv,
.ath-r-tr {
    background-position: 100% -108px
}

.ath-r-ru {
    background-position: 100% -128px
}

.ath-r-es {
    background-position: 100% -144px
}

.ath-r-hr {
    background-position: 100% -163px
}

.ath-r-hu {
    background-position: 100% -180px
}

.ath-r-fr {
    background-position: 100% -198px
}

.ath-r-fi {
    background-position: 100% -217px
}

.ath-r-el {
    background-position: 100% -234px
}

.ath-r-pt,
.ath-r-pt-br {
    background-position: 100% -253px
}

.ath-r-nl {
    background-position: 100% -269px
}

.ath-r-et {
    background-position: 100% -287px
}

.ath-r-no {
    background-position: 100% -306px
}

.ath-r-sl {
    background-position: 100% -325px
}

.ath-r-en-uk {
    background-position: 100% -342px
}

.banx-content_top {
    margin-top: 10px
}

@media only screen and (max-width:799px) {
    .links .adsenvelope {
        margin: 0 auto
    }
}