.ui-formButton {
    border: 1px solid grey;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
}
.ui-formButton.greenPrimary {
    background-color: var(--color-combination-4);
    color: var(--color-combination-14);
    font-weight: 700;
}
.ui-formButton.greenPrimary:hover {
    background-color: var(--color-combination-27);
}
.ui-formButton.greenPrimary:disabled {
    background-color: var(--color-support-2);
    color: #606060;
}
.ui-formButton.greenSecondary {
    background-color: transparent;
    border: 1px solid var(--color-secondary-3);
    color: var(--color-secondary-2);
    font-weight: 700;
}
.ui-formButton.greenSecondary:hover {
    background-color: var(--color-secondary-3);
    color: #fff;
}
.ui-formButton.greenSecondary:disabled {
    background-color: var(--color-support-2);
    color: #606060;
}
.ui-formButton.red {
    background-color: transparent;
    border: 1px solid var(--color-support-2);
    color: var(--color-red);
    font-weight: 700;
}
.ui-formButton.red:hover {
    background-color: var(--color-red);
    border-color: transparent;
    color: #fff;
}
.ui-formButton.red:disabled {
    background-color: var(--color-support-2);
    color: #606060;
}
.ui-button {
    -webkit-appearance: button;
    border: none;
    font-family: inherit;
    margin: 0;
    outline: none;
    overflow: visible;
}
.ui-button:not(:disabled) {
    cursor: pointer;
}
.ui-button__action {
    background-color: var(--color-text-2);
    border-radius: 10px;
    color: var(--color-text-1);
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 3;
    padding: 3px 14px 0;
    text-transform: uppercase;
}
.ui-button__action:hover {
    background-color: #dbdbdb;
}
.ui-button__sport {
    fill: var(--color-footballGoal);
    background-color: var(--color-background-13);
    border-bottom: 2px solid transparent;
    border-top: var(--color-footballGoal);
    color: var(--color-footballGoal);
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 16px 0;
    text-transform: uppercase;
}
.ui-button__sport:hover {
    fill: var(--color-white);
    border-top: var(--color-white);
    color: var(--color-white);
}
.ui-button__sport--selected {
    fill: var(--color-white);
    border-bottom-color: var(--color-white);
    color: var(--color-white);
}
.ui-button__sport .icon__inline {
    margin-right: 6px;
    margin-top: -2px;
}
.ui-button__sport .badge {
    margin-left: 6px;
}
.ui-button__terminate {
    background-color: transparent;
    color: var(--color-red);
    font-weight: 700;
    justify-self: center;
    min-height: 26px;
    padding: 4px 8px;
    text-decoration: underline;
    width: max-content;
}
.ui-button__terminate:hover {
    text-decoration: none;
}
.ui-button__dropdown {
    fill: var(--color-text-4);
    background-color: var(--color-white);
    border-radius: 6px;
    border-top: var(--color-text-4);
    color: var(--color-text-4);
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    padding: 6px 8px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}
.ui-button__dropdown:hover {
    fill: var(--color-text-highlight-4);
    background-color: var(--color-background-highlight-8);
    color: var(--color-text-highlight-4);
}
.ui-button__dropdown:hover .badge {
    background-color: var(--color-green-icon);
    color: var(--color-white);
}
.ui-button__dropdown .icon__inline {
    margin-right: 6px;
    margin-top: -2px;
}
.ui-button__dropdown .badge {
    background-color: var(--color-text-2);
    margin-left: 6px;
}
.ui-button__primary {
    background-color: var(--color-text-highlight-4);
    color: var(--color-white);
}
.ui-button__primary:hover {
    background-color: var(--color-green-icon);
}
.ui-button__live {
    background-color: var(--color-white);
    color: var(--color-live);
}
.ui-button__live:hover {
    background-color: var(--color-text-1);
    color: var(--color-live);
}
.ui-button__small {
    border-radius: 6px;
    font-size: 8px;
    padding: 0 10px;
}
.ui-button__large {
    border-radius: 14px;
    font-size: 12px;
    padding: 3px 20px 0;
}
.ui-buttonGroup > .button {
    border-radius: 0;
}
.ui-buttonGroup > .button:not(:last-child) {
    margin-right: 1px;
}
.ui-buttonGroup > .button:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.ui-buttonGroup > .button:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.ui-icon {
    max-height: 36px;
    max-width: 36px;
}
.ui-icon__inline {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;
}
.ui-icon svg {
    height: 100%;
    width: 100%;
}
.ui-media {
    display: grid;
    grid-area: media;
    grid-template-rows: 1fr auto;
    margin: 0;
}
.ui-media--video {
    margin-bottom: 24px;
}
.ui-media--preview {
    cursor: pointer;
}
.ui-media--article {
    font-size: 13px;
    margin-bottom: 24px;
}
.ui-media__placeholder {
    aspect-ratio: 3/2;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
@supports not (aspect-ratio: 3/2) {
    .ui-media__placeholder {
        padding: 0 0 66.66%;
    }
}
.ui-media__placeholder--video {
    aspect-ratio: 16/9;
    border-radius: 0;
    margin: 0;
}
@supports not (aspect-ratio: 16/9) {
    .ui-media__placeholder--video {
        padding: 0 0 56.25%;
    }
}
.ui-media__placeholder--youtube {
    aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
    .ui-media__placeholder--youtube {
        padding: 0 0 56.25%;
    }
}
.ui-media__image {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.66s ease-out;
    width: 100%;
}
.ui-media__image--loaded {
    opacity: 1;
}
.ui-media__image--with-priority {
    transition-duration: unset;
}
.ui-media__embed {
    aspect-ratio: 3/2;
    border-radius: 8px;
    margin-bottom: 17px;
    overflow: hidden;
    position: relative;
}
@supports not (aspect-ratio: 3/2) {
    .ui-media__embed {
        padding: 0 0 66.66%;
    }
}
.ui-media__embed--youtube {
    aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
    .ui-media__embed--youtube {
        padding: 0;
        z-index: 1;
    }
    .ui-media__embed--youtube iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
.ui-media__caption {
    font-size: 13px;
    margin-top: 8px;
}
.ui-badge {
    border-radius: 3px;
    font-size: 10px;
    font-weight: 700;
    padding: 2px;
}
.ui-badge__sport {
    background-color: var(--color-green-icon);
    color: var(--color-footballGoal);
}
.ui-badge__sport--selected {
    background-color: var(--color-text-highlight-4);
    color: var(--color-white);
}
.ui-badge__live {
    background-color: var(--color-live);
    color: var(--color-white);
}
.ui-table {
    display: grid;
}
.ui-table:first-child .ui-table__headerCell:first-child {
    border-radius: 0;
}
@media only screen and (min-width: 640px) {
    .ui-table:first-child .ui-table__headerCell:first-child {
        border-radius: 0 0 0 4px;
    }
}
.ui-table:first-child .ui-table__headerCell:last-child {
    border-radius: 0;
}
@media only screen and (min-width: 640px) {
    .ui-table:first-child .ui-table__headerCell:last-child {
        border-radius: 0 0 4px 0;
    }
}
.ui-table__header {
    border-bottom: 1px solid var(--color-border-4);
    display: flex;
    height: 29px;
    user-select: none;
}
.ui-table__headerCell {
    align-items: center;
    background-color: var(--color-support-1);
    color: var(--color-secondary-2);
    display: flex;
    font-size: 11px;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 32px;
}
.ui-table__headerCell:first-child {
    border-radius: 4px 0 0 4px;
}
.ui-table__headerCell:last-child {
    border-radius: 0 4px 4px 0;
}
.ui-table__headerCell--sortable:hover {
    cursor: pointer;
    text-decoration: underline;
}
.ui-table__headerCell--sorted {
    background-color: var(--color-support-3-alpha-30);
}
.ui-table__body {
    display: grid;
    grid-auto-rows: 36px;
}
.ui-table__row {
    border-bottom: 1px solid #eee;
    display: flex;
}
.theme--dark .ui-table__row {
    border-bottom: 1px solid hsla(180, 5%, 79%, 0.25);
}
.ui-table__sortArrow {
    color: var(--color-draw-text, var(--color-sport-secondary-body));
    height: 4px;
    margin-left: 3px;
    position: relative;
    width: 6px;
}
.ui-loading:before {
    animation: spin 1.8s ease-in-out infinite;
    border: 4px solid var(--color-support-1);
    border-radius: 50%;
    border-top-color: var(--color-seccondary-3);
    content: "";
    display: flex;
    height: 44px;
    width: 44px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}
.ui-message {
    bottom: 40px;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    z-index: var(--zIndex-tooltip);
}
.ui-message__content {
    align-items: center;
    background-color: #000;
    border-radius: 4px;
    color: var(--color-white);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    z-index: var(--zIndex-tooltip);
}
.ui-message__animation {
    animation: bounceAnimation 0.8s forwards;
}
@keyframes bounceAnimation {
    0% {
        bottom: 40px;
    }
    30% {
        bottom: 70px;
    }
    50% {
        bottom: 40px;
    }
    70% {
        bottom: 52px;
    }
    85% {
        bottom: 40px;
    }
    90% {
        bottom: 44px;
    }
    to {
        bottom: 40px;
    }
}
@keyframes fadeInWindow {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeInDimmer {
    0% {
        background-color: transparent;
    }
    to {
        background-color: rgba(0, 0, 0, 0.6);
    }
}
.modal__window--legalAgeConfirmation {
    border-radius: 12px;
    top: 80px;
}
.modal__window--legalAgeConfirmation > .modal__header {
    border-bottom: initial;
    height: auto;
    line-height: 23px;
    margin-bottom: 18px;
    padding: initial;
    text-align: center;
}
body {
    --range-modal: 48px;
}
.modal__dimmer {
    -webkit-text-size-adjust: 100%;
    animation: fadeInDimmer 0.25s linear;
    background-color: rgba(30, 36, 38, 0.6);
    bottom: 0;
    cursor: auto;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--zIndex-modalWindow);
}
.modal__dimmer--seoBarAbove {
    --range-modal: 76px;
}
._ass .modal__dimmer--seoBarAbove {
    --range-modal: 105px;
}
.modal__dimmer--centered {
    align-items: center;
    display: flex;
}
.modal__dimmer--lsid,
.modal__dimmer--search,
.modal__dimmer--settings {
    --range-modal: 48px;
}
.modal__dimmer--seoBarAbove.modal__dimmer--lsid,
.modal__dimmer--seoBarAbove.modal__dimmer--search,
.modal__dimmer--seoBarAbove.modal__dimmer--settings {
    --range-modal: 76px;
}
._ass .modal__dimmer--seoBarAbove.modal__dimmer--lsid,
._ass .modal__dimmer--seoBarAbove.modal__dimmer--search,
._ass .modal__dimmer--seoBarAbove.modal__dimmer--settings {
    --range-modal: 105px;
}
@media only screen and (max-width: 639px) {
    .modal__dimmer--lsid,
    .modal__dimmer--search,
    .modal__dimmer--settings {
        top: var(--range-modal);
    }
}
.modal__window {
    animation: fadeInWindow 0.6s ease-out;
    background-color: var(--color-support-5);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    left: 50%;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: absolute;
    transform: translateX(-50%);
    z-index: var(--zIndex-modalWindow);
}
@media only screen and (max-width: 639px) {
    .modal__window:not(.modal__window--registrationPrompt) {
        width: 100%;
    }
}
.modal__window--settings {
    border-radius: 12px;
    max-width: 538px;
    top: 90px;
    width: 100%;
}
@media only screen and (max-width: 1047px) {
    .modal__window--settings {
        max-height: calc(100vh - 100px);
        top: 90px;
    }
}
@media only screen and (max-width: 639px) {
    .modal__window--settings {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: border-box;
        height: 100%;
        left: auto;
        max-height: none;
        max-width: none;
        top: auto;
        transform: none;
        width: 100%;
    }
    ._ass .modal__window--settings {
        border-radius: 0;
    }
}
.modal__window--search {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-width: 538px;
    min-height: 320px;
    top: 90px;
    width: 100%;
}
@media only screen and (max-width: 1047px) {
    .modal__window--search {
        max-height: calc(100vh - 100px);
        top: 90px;
    }
}
@media only screen and (max-width: 639px) {
    .modal__window--search {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: border-box;
        height: 100%;
        left: auto;
        max-height: none;
        max-width: none;
        top: auto;
        transform: none;
        width: 100%;
    }
}
@media only screen and (max-height: 410px) {
    .modal__window--search {
        min-height: auto;
    }
}
@media only screen and (max-width: 639px) {
    ._ass .modal__window--search {
        border-radius: 0;
    }
    .modal__window--search .modal__header {
        display: none;
    }
}
.modal__window--lsid {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    top: 90px;
}
@media only screen and (max-width: 1047px) {
    .modal__window--lsid {
        max-height: calc(100vh - 100px);
        top: 90px;
    }
}
@media only screen and (max-width: 639px) {
    .modal__window--lsid {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: border-box;
        height: 100%;
        left: auto;
        max-height: none;
        max-width: none;
        top: auto;
        transform: none;
        width: 100%;
    }
}
.modal__window--registrationPrompt {
    border-radius: 8px;
    max-height: calc(100vh - 100px);
    max-width: 538px;
    top: 90px;
    width: 100%;
}
@media only screen and (max-width: 639px) {
    .modal__window--registrationPrompt {
        max-height: calc(100vh - 140px);
        top: 124px;
        width: calc(100% - 16px);
    }
}
.modal__window--langBoxSetup {
    background-color: var(--color-combination-9);
    border-radius: 8px;
    top: 20vh;
}
.modal__window--spainAgeConfirmationVariantB {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 48px);
    overflow: visible;
    padding: 40px 275px 40px 32px;
    top: unset;
    width: 600px;
}
@media only screen and (max-width: 799px) {
    .modal__window--spainAgeConfirmationVariantB {
        background-color: transparent;
        max-width: 288px;
        overflow: auto;
        padding: 159px 0 0;
    }
}
.modal__window--spainAgeConfirmationVariantC {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-width: 308px;
    padding: 16px 0;
    top: unset;
    width: 600px;
}
@media only screen and (max-width: 639px) {
    .modal__window--spainAgeConfirmationVariantC {
        max-width: 288px;
        padding: 16px 0 20px;
    }
}
.modal__window--spainAgeConfirmationVariantD {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 48px);
    max-width: 412px;
    padding: 44px 0 36px;
    top: unset;
    width: 600px;
}
@media only screen and (max-width: 639px) {
    .modal__window--spainAgeConfirmationVariantD {
        max-width: 288px;
        padding: 35px 0 28px;
    }
}
@media only screen and (max-width: 1047px) {
    .modal__window--fullScreenInResponsive {
        max-height: calc(100vh - 100px);
        top: 50px;
    }
}
@media only screen and (max-width: 799px) {
    .modal__window--fullScreenInResponsive {
        max-height: calc(100vh - 60px);
        top: 30px;
    }
}
@media only screen and (max-width: 639px) {
    .modal__window--fullScreenInResponsive {
        box-sizing: border-box;
        height: 100%;
        left: auto;
        max-height: none;
        padding: 10px 0 0;
        top: auto;
        transform: none;
        width: 100%;
    }
}
.modal__window--setupGuideTourBox {
    display: block;
}
.modal__window--legalAgeConfirmation {
    max-width: 320px;
}
.modal__window--legalAgeConfirmation.modal__window--legalAgeConfirmation {
    padding: 32px 20px;
}
.modal__closeButton {
    border: none;
    color: var(--color-combination-18);
    cursor: pointer;
    height: 16px;
    outline: none;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 16px;
    z-index: 1;
}
.modal__closeButton--center {
    top: calc(50% - 8px);
}
.modal__window--lsid .modal__closeButton,
.modal__window--registrationPrompt .modal__closeButton {
    z-index: 2;
}
@media only screen and (max-width: 639px) {
    .modal__window----settings .modal__closeButton,
    .modal__window--search .modal__closeButton {
        display: none;
    }
}
.modal__closeButton:hover {
    color: var(--color-support-4);
}
.modal__header {
    align-items: center;
    border-bottom: 1px solid var(--color-support-1);
    color: var(--color-combination-1);
    display: flex;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 700;
    height: 56px;
    padding-right: 58px;
    position: relative;
}
@media only screen and (max-width: 639px) {
    .modal__header--settings {
        display: none;
    }
}
.lsidDetail .modal__header {
    border: initial;
    height: auto;
    justify-content: center;
    padding: initial;
    padding-top: 16px;
    position: static;
}
.ui-input {
    background-color: var(--color-combination-11);
    border: 1px solid var(--color-combination-12);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--color-combination-2);
    padding: 8px;
    width: 100%;
}
.ui-input:focus {
    border-color: var(--color-combination-13);
    outline: none;
}
.ui-input.invalid:not(:focus) {
    border: 1px solid var(--color-red);
}
.passwordInput__wrapper {
    position: relative;
}
.passwordInput__showPasswordButton {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 12px;
    top: 10px;
}
.passwordInput__showPasswordButton:hover {
    opacity: 0.7;
}
.passwordInput__icon {
    height: 20px;
    width: 20px;
}
.radioButton {
    cursor: pointer;
}
.radioButton:hover .radioButton__original:not(:checked) ~ .radioButton__button {
    border-color: var(--color-combination-24);
}
.radioButton__original {
    display: none;
}
.radioButton__original:checked ~ .radioButton__button {
    border-color: var(--color-green);
}
.radioButton__original:checked ~ .radioButton__button:before {
    background-color: var(--color-green);
}
.radioButton__button {
    border: 1px solid var(--color-support-2);
    border-radius: 100%;
    box-sizing: border-box;
    height: 16px;
    position: relative;
    transition: border 0.25s;
    width: 16px;
}
.radioButton__button--langBoxSetup {
    height: 20px;
    width: 20px;
}
.radioButton__button:before {
    border-radius: 100%;
    content: "";
    height: 10px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: background 0.25s;
    width: 10px;
}
.radioButton__button--langBoxSetup.radioButton__button:before {
    height: 14px;
    width: 14px;
}
.ui-divider {
    border: none;
    border-bottom: 1px solid #e5e5e5;
}
.ui-section {
    background-color: var(--color-combination-9);
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 12px;
}
.ui-section--shifted {
    margin-top: 12px;
}
.ui-section__title {
    color: var(--color-combination-2);
    font-size: 18px;
    font-weight: 700;
    padding: 12px 4px 16px;
}
@media only screen and (max-width: 639px) {
    .ui-section {
        border-radius: 0;
    }
    .ui-section--topIndented {
        padding: 12px 0 0;
    }
    .ui-section--noIndent {
        padding: 0;
    }
    .ui-section--indented {
        padding: 12px 0;
    }
    .ui-section--indented .ui-section__title {
        padding: 12px;
    }
    .ui-section--topIndented .ui-section__title {
        padding: 8px 12px 12px;
    }
}
@media only screen and (max-width: 479px) {
    .ui-section {
        padding: 12px 0;
    }
    .ui-section--topIndented {
        padding: 12px 0 0;
    }
    .ui-section--noIndent {
        padding: 0;
    }
    .ui-section__title {
        padding: 8px 12px 16px;
    }
}
.ui-breadcrumb__item-sport {
    display: inline-block;
    font-weight: 700;
    position: relative;
    transform: translateX(-7px);
}
.ui-breadcrumb__item-sport__icon {
    height: 25px;
    margin-bottom: -6px;
    margin-top: -6px;
    transform: scale(0.7);
    transform-origin: center;
    vertical-align: middle;
    width: 25px;
}
.ui-breadcrumb__item-sport + .ui-breadcrumb__item-flag:before {
    border-style: solid;
    border-width: 0.15625em 0.15625em 0 0;
    color: var(--color-text-1);
    content: "";
    display: inline-block;
    height: 4px;
    position: absolute;
    right: 9px;
    top: 4px;
    transform: rotate(45deg);
    width: 4px;
}
.ui-breadcrumb__item-flag {
    margin-left: 11px;
    margin-right: 8px;
    position: relative;
}
.ui-breadcrumb__item-flag__icon {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.ui-breadcrumb__item-country {
    line-height: 17px;
}
.ui-show-more {
    align-items: center;
    color: var(--color-secondary-3-active);
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    text-decoration: underline;
}
.ui-show-more--border {
    border-top: 1px solid var(--color-support-1);
}
.ui-show-more--stretch {
    margin: -12px;
    padding: 16px;
}
@media only screen and (max-width: 799px) {
    .ui-show-more--stretch {
        margin: 0 -12px -12px;
    }
}
@media only screen and (max-width: 639px) {
    .ui-show-more--stretch {
        margin: 0;
    }
}
.ui-show-more:hover {
    text-decoration: none;
}
.ui-tabs__tab {
    align-items: center;
    background-color: var(--color-background-4);
    cursor: pointer;
    display: flex;
    height: 28px;
    margin-right: 1px;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
}
.ui-tabs__tab,
.ui-tabs__tab:focus,
.ui-tabs__tab:hover {
    text-decoration: none;
}
.ui-tabs__tab:focus,
.ui-tabs__tab:hover {
    background-color: var(--color-background-hover-2);
}
.ui-tabs__tab--selected {
    background-color: var(--color-tab, var(--color-sport-secondary-body));
    color: var(--color-white);
    font-weight: 700;
    pointer-events: none;
}
.ui-tabs__tab--selected:focus,
.ui-tabs__tab--selected:hover {
    background-color: var(--color-background-4);
}
.ui-tabs__text--short {
    display: none;
}
@media screen and (max-width: 799px) {
    .ui-tabs__text--short {
        display: block;
    }
    .ui-tabs__text--long {
        display: none;
    }
}
.ui-tabs__group {
    border-bottom: 4px solid var(--color-tab, var(--color-sport-secondary-body));
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
    width: 100%;
}
