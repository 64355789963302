.ui-formButton {
    border: 1px solid grey;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px;
    width: 100px;
}

.ui-formButton.greenPrimary {
    background-color: var(--color-combination-4);
    color: var(--color-combination-14);
    font-weight: 700
}

.ui-formButton.greenPrimary:hover {
    background-color: var(--color-combination-27)
}

.ui-formButton.greenPrimary:disabled {
    background-color: var(--color-support-2);
    color: #606060
}

.ui-formButton.greenSecondary {
    background-color: transparent;
    border: 1px solid var(--color-secondary-3);
    color: var(--color-secondary-2);
    font-weight: 700
}

.ui-formButton.greenSecondary:hover {
    background-color: var(--color-secondary-3);
    color: #fff
}

.ui-formButton.greenSecondary:disabled {
    background-color: var(--color-support-2);
    color: #606060
}

.ui-formButton.red {
    background-color: transparent;
    border: 1px solid var(--color-support-2);
    color: var(--color-red);
    font-weight: 700
}

.ui-formButton.red:hover {
    background-color: var(--color-red);
    border-color: transparent;
    color: #fff
}

.ui-formButton.red:disabled {
    background-color: var(--color-support-2);
    color: #606060
}

.ui-button {
    -webkit-appearance: button;
    border: none;
    font-family: inherit;
    margin: 0;
    outline: none;
    overflow: visible
}

.ui-button:not(:disabled) {
    cursor: pointer
}

.ui-button__action {
    background-color: var(--color-text-2);
    border-radius: 10px;
    color: var(--color-text-1);
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 3;
    padding: 3px 14px 0;
    text-transform: uppercase
}

.ui-button__action:hover {
    background-color: #dbdbdb
}

.ui-button__sport {
    fill: var(--color-footballGoal);
    background-color: var(--color-background-13);
    border-bottom: 2px solid transparent;
    border-top: var(--color-footballGoal);
    color: var(--color-footballGoal);
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 16px 0;
    text-transform: uppercase
}

.ui-button__sport:hover {
    fill: var(--color-white);
    border-top: var(--color-white);
    color: var(--color-white)
}

.ui-button__sport--selected {
    fill: var(--color-white);
    border-bottom-color: var(--color-white);
    color: var(--color-white)
}

.ui-button__sport .icon__inline {
    margin-right: 6px;
    margin-top: -2px
}

.ui-button__sport .badge {
    margin-left: 6px
}

.ui-button__terminate {
    background-color: transparent;
    color: var(--color-red);
    font-weight: 700;
    justify-self: center;
    min-height: 26px;
    padding: 4px 8px;
    text-decoration: underline;
    width: max-content
}

.ui-button__terminate:hover {
    text-decoration: none
}

.ui-button__dropdown {
    fill: var(--color-text-4);
    background-color: var(--color-white);
    border-radius: 6px;
    border-top: var(--color-text-4);
    color: var(--color-text-4);
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    padding: 6px 8px;
    text-align: left;
    text-transform: uppercase;
    width: 100px;
}

.ui-button__dropdown:hover {
    fill: var(--color-text-highlight-4);
    background-color: var(--color-background-highlight-8);
    color: var(--color-text-highlight-4)
}

.ui-button__dropdown:hover .badge {
    background-color: var(--color-green-icon);
    color: var(--color-white)
}

.ui-button__dropdown .icon__inline {
    margin-right: 6px;
    margin-top: -2px
}

.ui-button__dropdown .badge {
    background-color: var(--color-text-2);
    margin-left: 6px
}

.ui-button__primary {
    background-color: var(--color-text-highlight-4);
    color: var(--color-white)
}

.ui-button__primary:hover {
    background-color: var(--color-green-icon)
}

.ui-button__live {
    background-color: var(--color-white);
    color: var(--color-live)
}

.ui-button__live:hover {
    background-color: var(--color-text-1);
    color: var(--color-live)
}

.ui-button__small {
    border-radius: 6px;
    font-size: 8px;
    padding: 0 10px
}

.ui-button__large {
    border-radius: 14px;
    font-size: 12px;
    padding: 3px 20px 0
}

.ui-buttonGroup>.button {
    border-radius: 0
}

.ui-buttonGroup>.button:not(:last-child) {
    margin-right: 1px
}

.ui-buttonGroup>.button:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px
}

.ui-buttonGroup>.button:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px
}

.ui-icon {
    max-height: 36px;
    max-width: 36px
}

.ui-icon__inline {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px
}

.ui-icon svg {
    height: 100%;
    width: 100px;
}

.ui-media {
    display: grid;
    grid-area: media;
    grid-template-rows: 1fr auto;
    margin: 0
}

.ui-media--video {
    margin-bottom: 24px
}

.ui-media--preview {
    cursor: pointer
}

.ui-media--article {
    font-size: 13px;
    margin-bottom: 24px
}

.ui-media__placeholder {
    aspect-ratio: 3/2;
    border-radius: 8px;
    overflow: hidden;
    position: relative
}



.ui-media__placeholder--video {
    aspect-ratio: 16/9;
    border-radius: 0;
    margin: 0
}
