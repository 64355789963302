div.tree{
    background: #fff;
}
div.tree ul {
    margin: 0px;
    padding: 0px;
}

div.tree ul li {
    list-style-type: none;
}

div.tree ul li .mainList {
    display: flex;
    align-items: center;
    width: 100%;
}

div.tree ul li .mainList .list,div.tree ul li .mainList .final {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-bottom: 15px;
}

div.tree ul li .mainList .list .group, div.tree ul li .mainList .final .group {
    display: inline-block;
    vertical-align: middle;
    width: 48%;
    position: relative;
}

div.tree ul li .mainList .list  .item, div.tree ul li .mainList .final  .item {
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    margin-bottom: 15px;
    padding: 15px 20px 15px;
}

div.tree ul li .mainList .list .item:last-child, div.tree ul li .mainList .final .item:last-child{
    margin-bottom: 0px;
}

div.tree ul li .mainList .list  .item label, div.tree ul li .mainList .final  .item label {}

div.tree ul li .mainList .list  .item label, div.tree ul li .mainList .final  .item label {
    display: block;
    font-size: 16px;
    color: rgb(0 0 0 / 60%);
    position: relative;
}

div.tree ul li .mainList .list  .item label.bold, div.tree ul li .mainList .final  .item label.bold {
    font-weight: 600;
    color: #000;
}

div.tree ul li .mainList .list  .item label img, div.tree ul li .mainList .final  .item label img {
    width: 40px;
    margin-right: 15px;
}

div.tree ul li .mainList .list  .item label span, div.tree ul li .mainList .final  .item label span {
    float: right;
}

ul.topHeading {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eeeeee;
    padding: 15px 0px;
}

ul.topHeading li {
    list-style-type: none;
    width: 33%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}


div.tree ul li .mainList .final {
    display: inline-block;
    vertical-align: middle;
    width: 33%;
}

div.tree ul li .mainList .list  .item label+label, div.tree ul li .mainList .final .item label+label {
    margin-top: 10px;
}

div.tree ul li .mainList .list .group.first:before {
    content: "";
    position: absolute;
    top: 0px;
    right: -31px;
    width: 31px;
    height: 75%;
    background: url('images/big-afterlines.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 0.4;
    bottom: 0px;
    margin: auto;
}
div.tree ul li .mainList .inner:before{
    content: "";
    position: absolute;
    top: 0px;
    right: -18px;
    width: 31px;
    height: 50%;
    background: url('images/big-afterlines.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 0.4;
    bottom: 0px;
    margin: auto;
}

div.tree ul li .mainList .list .group.second .item, div.tree ul li .mainList .final .group.second .item {
    margin-bottom: 0px;
}

div.tree ul li .mainList:last-child .list .item:last-child, div.tree ul li .mainList:last-child .final .item:last-child {
    margin-bottom: 0px;
}

div.tree ul li .mainList .final .item {
    margin-bottom: 0px;
}

div.tree ul li .mainList .inner {
    display: block;
    width: 66%;
    position: relative;
}

div.tree ul li .mainList .list .group.second, div.tree ul li .mainList .final {
    padding-left: 26px;
    position: relative;
}

div.tree ul li .mainList:last-child .list:last-child, div.tree ul li .mainList:last-child .final:last-child {
    margin-bottom: 0px;
}

div.tree ul li .mainList .final {
    padding-left: 18px;
}

div.tree ul li .mainList .inner.last:before {
    display: none;
}

div.tree {
    padding: 40px 40px 50px;
}

span.preview-ico.icon--preview {
    color: #000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
} 

nav.menuTop div.menuTop__items {
    display: flex;
    flex-flow: unset;
    align-items: center;
}

nav.menuTop div.menuTop__items a.menuTop__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-right: 40px;
}

nav.menuTop div.menuTop__items a.menuTop__item div.menuTop__text {
    display: inline-block;
    vertical-align: middle;
}

nav.menuTop div.menuTop__items a.menuTop__item img {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    margin-right: 8px;
}

nav.menuTop div.menuTop__items a.menuTop__item:after {
    right: 15px;
    left: unset;
    top: 0px;
    bottom: 0px;
    margin: auto;
    height: 18px;
}

nav.menuTop div.menuTop__items a.menuTop__item:last-child {
    padding-right: 0px;
}

@media (max-width:1000px){
aside#lc {
    display: none;
}

div#tc {
    display: block;
    width: 100%;
}

.container {
    display: block;
    padding: 0px;
}

nav.menuTop.menuTop--soccer {
    display: block;
}

body {
    width: 100%;
    overflow-x: hidden;
}

nav.menuTop.menuTop--soccer .menuTop__content.menuTop__group {
    display: block;
}

#live-table .event__time,#live-table .event__stage {
    grid-area: unset;
    height: max-content;
    display: inline-flex;
    align-items: center;
    font-size: 11px;
    width: 50px;
    padding: 18px 0px;
    margin: 0px;
    justify-content: center;
}

#live-table div.event__match {
}

.calendar__navigation {
    display: none;
}

.filters__tab {
    font-size: 10px;
}

#live-table 
 .eventSubscriber.eventSubscriber__star.eventSubscriber__star--event {
    display: none;
}


.mlPopUpContainer {
    display: none;
}

.event__header {
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
}

.event__header .icon--flag {
    flex: unset;
}

span.event__expanderBlock {
    display: none;
}

span.preview-ico.icon--preview {
    position: relative;
    top: -2px;
}

nav.menuTop div.menuTop__items a.menuTop__item img {
    width: 22px;
    margin-right: 8px;
}

nav.menuTop div.menuTop__items a.menuTop__item div.menuTop__text {
    font-size: 12px;
}

nav.menuTop div.menuTop__items {padding: 12px 8px;overflow-x: scroll;}

nav.menuTop div.menuTop__items a.menuTop__item {
    display: inline-flex;
    justify-content: flex-start;
    padding-right: 35px;
}

nav.menuTop div.menuTop__items a.menuTop__item:after {
    right: 12px;
}

.menuTop__item--active:before {
    display: none;
}
div.tree {
    padding: 20px 10px 20px;
}

div.tree ul li .mainList .list .item, div.tree ul li .mainList .final .item {
    margin-bottom: 15px;
    padding: 10px 10px 10px;
}

div.tree ul li .mainList .list .item label, div.tree ul li .mainList .final .item label {
    font-size: 10px;
}

div.tree ul li .mainList .list .item label img, div.tree ul li .mainList .final .item label img {
    display: block;
    margin-bottom: 6px;
}

div.tree ul li .mainList .list .group, div.tree ul li .mainList .final .group {
    width: 45%;
    z-index: 999;
}

div.tree ul li .mainList .inner {width: 70%;}

div.tree ul li .mainList .list .group.second, div.tree ul li .mainList .final {
    padding-left: 20px;
}

div.tree ul li .mainList .list .group.first:before {
    right: -22px;
    z-index: 9;
}

div.tree ul li .mainList .list .item, div.tree ul li .mainList .final .item {background: #fff;overflow: hidden;z-index: 9999;position: relative;}

ul.topHeading li {
    font-size: 14px;
}    
}