.dropdown {
    height: var(--dropdown-height, 28px);
    position: var(--dropdown-position, relative);
    user-select: none;
}
.dropdown--horseRacing {
    --option-justifyContent: center;
    --arrow-marginLeft: 12px;
    --button-padding: 0 13px;
    margin-left: auto;
}
.dropdown__selectedValue {
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--color-support-5);
    border: var(--button-border, initial);
    border-radius: 8px;
    color: var(--color-support-4);
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 13px;
    font-weight: 700;
    height: 100%;
    justify-content: space-between;
    padding: var(--button-padding, 0 10px);
    text-align: left;
    width: 100%;
}
.dropdown__selectedValue--open,
.dropdown__selectedValue:hover {
    color: var(--color-combination-1);
}
.dropdown__list {
    background-color: var(--color-support-5);
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    cursor: default;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: var(--list-gridAutoRows, minmax(28px, max-content));
    list-style-type: none;
    margin: initial;
    max-height: var(--list-maxHeight, 470px);
    min-width: var(--list-minWidth, 100%);
    overflow-y: auto;
    padding: 8px;
    position: absolute;
    right: 0;
    row-gap: 4px;
    top: calc(100% + 2px);
    z-index: var(--zIndex-dropDown);
}
.dropdown__listItem {
    overflow: var(--listItem-overflow, initial);
}
.dropdown__option {
    align-items: center;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: initial;
    border: initial;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--color-combination-1);
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 13px;
    height: 100%;
    hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    justify-content: var(--option-justifyContent, initial);
    margin: initial;
    padding: 4px 8px;
    text-align: left;
    width: 100%;
}
.dropdown__option--selected {
    background: var(--color-primary-2);
    color: var(--color-white);
    font-weight: 700;
}
.dropdown__option:not(.dropdown__option--selected):hover {
    background-color: rgba(var(--color-support-2-rgb), 0.3);
    color: var(--color-combination-1);
}
.dropdown__flag {
    display: flex;
    margin-right: 1ex;
}
.dropdown__text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dropdown__arrow {
    display: flex;
    flex-shrink: 0;
    height: 10px;
    margin-left: var(--arrow-marginLeft, 1ex);
    top: calc(50% - 3px);
    transform: rotate(-90deg);
    transition: transform 0.2s;
    transition-timing-function: ease-in-out;
    width: 10px;
}
.dropdown__arrow--open {
    transform: rotate(90deg);
}
