.toggle {
    --color-toggle-1: #eee;
    --color-toggle-2: #999;
    background-color: var(--color-toggle-1);
    border-radius: 10px;
    cursor: pointer;
    flex-shrink: 0;
    height: 20px;
    position: relative;
    transition: background-color .25s;
    width: 32px;
}

.theme--dark .toggle {
    --color-toggle-1: #555e61;
    --color-toggle-2: #eee;
}

.toggle:after {
    background-color: var(--color-toggle-2);
    border-radius: 6px;
    content: "";
    display: flex;
    height: 12px;
    left: 4px;
    position: absolute;
    top: calc(50% - 6px);
    transition: left .25s, background-color .25s;
    width: 12px
}

.toggle--ranking {
    background-color: var(--color-white)
}

.theme--dark .toggle--ranking {
    background-color: #555e61
}

.theme--dark .toggle--ranking.toggle--on,
.toggle--on {
    background-color: var(--color-green)
}

.toggle--on:after {
    background-color: var(--color-white);
    left: calc(100% - 16px)
}

.theme--dark .toggle--darkTheme {
    background-color: var(--color-green)
}

.theme--dark .toggle--darkTheme:after {
    background-color: var(--color-white);
    left: calc(100% - 16px)
}

.toggleButton {
    --color-toggle-1: #eee;
    --color-toggle-2: #999;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: var(--color-green);
    border-radius: 10px;
    cursor: pointer;
    flex-shrink: 0;
    height: 20px;
    margin: initial;
    position: relative;
    transition: background-color .25s;
    width: 32px
}

.theme--dark .toggleButton {
    --color-toggle-1: #555e61;
    --color-toggle-2: #eee
}

.toggleButton:after {
    background-color: var(--color-white);
    border-radius: 6px;
    content: "";
    display: flex;
    height: 12px;
    left: calc(100% - 16px);
    position: absolute;
    top: calc(50% - 6px);
    transition: left .25s, background-color .25s;
    width: 12px
}

.alert--notificationsStatus--off .toggleButton,
.notificationsDialog__option .toggleButton:not(:checked) {
    background-color: var(--color-toggle-1)
}

.alert--notificationsStatus--off .toggleButton:after,
.notificationsDialog__option .toggleButton:not(:checked):after {
    background-color: var(--color-toggle-2);
    border-radius: 6px;
    content: "";
    display: flex;
    height: 12px;
    left: 4px;
    position: absolute;
    top: calc(50% - 6px);
    transition: left .25s, background-color .25s;
    width: 12px
}

.messageCont {
    background-color: var(--color-combination-9);
    border-radius: 8px 8px 0 0;
    display: grid;
    padding: 16px 12px 0
}

.detailbody .messageCont {
    padding: 0
}

.messageCont~.filters {
    background-color: var(--color-combination-9);
    border-radius: 0;
    padding: 0 12px
}

@media only screen and (max-width:639px) {
    .messageCont {
        border-radius: 0;
        padding: 0
    }
    .messageCont~.filters {
        padding: 0
    }
}

.message {
    border-radius: 8px;
    cursor: pointer;
    display: grid;
    font-size: 13px;
    letter-spacing: .4px;
    position: relative;
    user-select: none
}

.message--formatOdds {
    grid-column-gap: 16px;
    align-items: center;
    background-color: var(--color-highlight);
    color: var(--color-combination-2);
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr max-content;
    margin-bottom: 16px;
    padding: 16px
}

.detailbody .message--formatOdds {
    margin: 0 12px 12px
}

.message--formatOdds .message__icon {
    height: 20px;
    width: 20px
}

.message--formatOdds .message__text strong {
    text-decoration: underline
}

.message--formatOdds .message__close {
    color: var(--color-support-3);
    height: 12px;
    padding: 4px;
    width: 12px
}

.message--formatOdds .message__close:hover {
    opacity: .7
}

@media only screen and (max-width:639px) {
    .message {
        border-radius: initial
    }
    .message--formatOdds {
        margin-bottom: 1px
    }
    .detailbody .message--formatOdds {
        margin: 0 0 12px
    }
}

.alert {
    border-radius: 8px;
    cursor: pointer;
    display: grid;
    font-size: 13px;
    letter-spacing: .4px;
    position: relative;
    user-select: none
}

.alert__icon {
    grid-area: icon
}

.alert__text {
    grid-area: text
}

.alert__button {
    grid-area: button
}

.alert--notifications {
    background-color: var(--color-highlight);
    color: var(--color-combination-2);
    grid-template: " icon   .      text   .      " max-content " .      .      .      .      " 12px " button button button button " minmax(28px, max-content) /20px 12px 1fr 32px;
    margin: 8px 10px;
    padding: 16px
}

.alert--notifications .alert__icon {
    align-self: center;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230f2d37'%3E%3Cpath d='M.992.207 20 18.827l-.992.972-2.61-2.558h-3.264C12.957 18.788 11.628 20 10.002 20c-1.627 0-2.956-1.212-3.133-2.76H3.51l-1-2.94 2.244-2.2.638-5.64L0 1.178zM11.696 17.24H8.307a1.735 1.735 0 0 0 1.695 1.386c.85 0 1.526-.603 1.694-1.386zM6.663 7.705l-.569 5.027-1.98 1.939.407 1.196h10.474zm4.04-7.706v2.762h1.579l2.27 3.171.683 6.047-1.587-1.555-.45-3.99-1.645-2.3H8.45l-.504.705-1.007-.986.782-1.092H9.3V0h1.402z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px
}

.theme--dark .alert--notifications .alert__icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23eee'%3E%3Cpath d='M.992.207 20 18.827l-.992.972-2.61-2.558h-3.264C12.957 18.788 11.628 20 10.002 20c-1.627 0-2.956-1.212-3.133-2.76H3.51l-1-2.94 2.244-2.2.638-5.64L0 1.178zM11.696 17.24H8.307a1.735 1.735 0 0 0 1.695 1.386c.85 0 1.526-.603 1.694-1.386zM6.663 7.705l-.569 5.027-1.98 1.939.407 1.196h10.474zm4.04-7.706v2.762h1.579l2.27 3.171.683 6.047-1.587-1.555-.45-3.99-1.645-2.3H8.45l-.504.705-1.007-.986.782-1.092H9.3V0h1.402z'/%3E%3C/svg%3E")
}

.alert--notifications .alert__close {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 20 20'%3E%3Cpath d='m19 0-9 9-9-9-1 1 9 9-9 9 1 1 9-9 9 9 1-1-9-9 9-9z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    height: 12px;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 12px
}

.alert--notifications .alert__text {
    align-self: center;
    color: var(--color-secondary-2);
    font-size: 13px;
    letter-spacing: .4px
}

.alert--notifications .alert__button {
    align-items: center;
    background-color: var(--color-secondary-1);
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase
}

@media only screen and (min-width:639px) {
    .alert--notifications {
        margin: 8px 0
    }
}

.alert--notificationsStatus {
    --opacity-1: 0.08;
    grid-column-gap: 8px;
    align-items: center;
    background-color: rgba(var(--color-green-rgb), var(--opacity-1));
    grid-template: " icon text button " minmax(20px, max-content) /20px 1fr max-content;
    margin: 16px 12px 0;
    padding: 12px
}

.theme--dark .alert--notificationsStatus {
    --opacity-1: 0.4
}

.alert--notificationsStatus .alert__icon {
    align-self: center;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230f2d37'%3E%3Cpath d='M10.687 0v2.761h1.547l2.223 3.17.682 6.168 2.2 2.2-.981 2.942H13.07c-.175 1.547-1.476 2.76-3.07 2.76-1.593 0-2.895-1.213-3.07-2.76H3.64L2.663 14.3l2.199-2.2.682-6.168 2.224-3.17h1.546V.001h1.374zm.973 17.241H8.34c.165.783.828 1.385 1.66 1.385s1.496-.602 1.66-1.385zm-.141-13.106H8.48L6.87 6.433l-.697 6.298-1.94 1.94.398 1.196h10.737l.399-1.197-1.94-1.939-.696-6.298-1.612-2.298zM4.335.035l.414 1.31a4.8 4.8 0 0 0-2.83 2.38 4.805 4.805 0 0 0-.317 3.685l-1.309.414A6.168 6.168 0 0 1 .7 3.092 6.155 6.155 0 0 1 4.335.036zm11.33 0a6.16 6.16 0 0 1 3.636 3.057 6.162 6.162 0 0 1 .406 4.73l-1.309-.412a4.805 4.805 0 0 0-.316-3.685 4.798 4.798 0 0 0-2.831-2.38zM5.168 2.666l.415 1.308a2.076 2.076 0 0 0-1.35 2.603l-1.31.414a3.423 3.423 0 0 1 .226-2.628 3.423 3.423 0 0 1 2.02-1.697zm9.664 0a3.421 3.421 0 0 1 2.018 1.697 3.42 3.42 0 0 1 .226 2.628l-1.309-.414a2.076 2.076 0 0 0-1.35-2.603z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    grid-area: icon;
    height: 20px;
    width: 20px
}

.theme--dark .alert--notificationsStatus .alert__icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23eee'%3E%3Cpath d='M10.687 0v2.761h1.547l2.223 3.17.682 6.168 2.2 2.2-.981 2.942H13.07c-.175 1.547-1.476 2.76-3.07 2.76-1.593 0-2.895-1.213-3.07-2.76H3.64L2.663 14.3l2.199-2.2.682-6.168 2.224-3.17h1.546V.001h1.374zm.973 17.241H8.34c.165.783.828 1.385 1.66 1.385s1.496-.602 1.66-1.385zm-.141-13.106H8.48L6.87 6.433l-.697 6.298-1.94 1.94.398 1.196h10.737l.399-1.197-1.94-1.939-.696-6.298-1.612-2.298zM4.335.035l.414 1.31a4.8 4.8 0 0 0-2.83 2.38 4.805 4.805 0 0 0-.317 3.685l-1.309.414A6.168 6.168 0 0 1 .7 3.092 6.155 6.155 0 0 1 4.335.036zm11.33 0a6.16 6.16 0 0 1 3.636 3.057 6.162 6.162 0 0 1 .406 4.73l-1.309-.412a4.805 4.805 0 0 0-.316-3.685 4.798 4.798 0 0 0-2.831-2.38zM5.168 2.666l.415 1.308a2.076 2.076 0 0 0-1.35 2.603l-1.31.414a3.423 3.423 0 0 1 .226-2.628 3.423 3.423 0 0 1 2.02-1.697zm9.664 0a3.421 3.421 0 0 1 2.018 1.697 3.42 3.42 0 0 1 .226 2.628l-1.309-.414a2.076 2.076 0 0 0-1.35-2.603z'/%3E%3C/svg%3E")
}

.alert--notificationsStatus--off {
    background-color: transparent
}

.alert--notificationsStatus--off .alert__icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23555e61'%3E%3Cpath d='M.992.207 20 18.827l-.992.972-2.61-2.558h-3.264C12.957 18.788 11.628 20 10.002 20c-1.627 0-2.956-1.212-3.133-2.76H3.51l-1-2.94 2.244-2.2.638-5.64L0 1.178zM11.696 17.24H8.307a1.735 1.735 0 0 0 1.695 1.386c.85 0 1.526-.603 1.694-1.386zM6.663 7.705l-.569 5.027-1.98 1.939.407 1.196h10.474zm4.04-7.706v2.762h1.579l2.27 3.171.683 6.047-1.587-1.555-.45-3.99-1.645-2.3H8.45l-.504.705-1.007-.986.782-1.092H9.3V0h1.402z'/%3E%3C/svg%3E")
}

.theme--dark .alert--notificationsStatus--off .alert__icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23eee'%3E%3Cpath d='M.992.207 20 18.827l-.992.972-2.61-2.558h-3.264C12.957 18.788 11.628 20 10.002 20c-1.627 0-2.956-1.212-3.133-2.76H3.51l-1-2.94 2.244-2.2.638-5.64L0 1.178zM11.696 17.24H8.307a1.735 1.735 0 0 0 1.695 1.386c.85 0 1.526-.603 1.694-1.386zM6.663 7.705l-.569 5.027-1.98 1.939.407 1.196h10.474zm4.04-7.706v2.762h1.579l2.27 3.171.683 6.047-1.587-1.555-.45-3.99-1.645-2.3H8.45l-.504.705-1.007-.986.782-1.092H9.3V0h1.402z'/%3E%3C/svg%3E")
}

.alert--notificationsStatus--off .alert__text {
    color: var(--color-support-4)
}

.alert--notificationsStatus .alert__text {
    font-size: 13px;
    font-weight: 700
}

.alert--notificationsStatus .alert__checkBox {
    grid-area: button
}

.caption-box {
    border-radius: 8px;
    color: var(--color-combination-2);
    display: grid;
    font-size: 13px;
    letter-spacing: .4px;
    margin: 10px 10px 0;
    padding: 8px 48px;
    position: relative;
    user-select: none
}

#service-status .caption-box {
    background-color: var(--color-live);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 17.3'%3E%3Cpath fill='%23dc0000' d='M9.4 0 0 16.3l.6 1h18.9l.6-1L10.6 0zM9 12.1h2V4.9H9zm0 3.3h2v-2H9z'/%3E%3Cpath fill='%23fff' d='M11 13.2v2H9v-2zm0-8.5v7.2H9V4.7z'/%3E%3C/svg%3E");
    background-position: 16px;
    background-repeat: no-repeat;
    background-size: 20px
}

.caption-box .close-button {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 20 20'%3E%3Cpath d='m19 0-9 9-9-9-1 1 9 9-9 9 1 1 9-9 9 9 1-1-9-9 9-9z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    cursor: pointer;
    height: 12px;
    position: absolute;
    right: 16px;
    top: calc(50% - 6px);
    width: 12px
}

.caption-box .close-button:hover {
    opacity: .7
}

.serviceStatus {
    background-color: var(--color-live);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 17.3'%3E%3Cpath fill='%23dc0000' d='M9.4 0 0 16.3l.6 1h18.9l.6-1L10.6 0zM9 12.1h2V4.9H9zm0 3.3h2v-2H9z'/%3E%3Cpath fill='%23fff' d='M11 13.2v2H9v-2zm0-8.5v7.2H9V4.7z'/%3E%3C/svg%3E");
    background-position: 16px;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 8px;
    color: var(--color-combination-2);
    display: grid;
    font-size: 13px;
    letter-spacing: .4px;
    margin: 10px 10px 0;
    padding: 8px 48px;
    position: relative;
    user-select: none
}

.serviceStatus__closeArea {
    display: flex;
    position: absolute;
    right: 16px;
    top: calc(50% - 6px)
}

.serviceStatus__closeIcon {
    cursor: pointer;
    height: 12px;
    width: 12px
}

.serviceStatus__closeIcon:hover {
    opacity: .7
}

.dimmer {
    background-color: #000;
    display: block;
    height: 100%;
    left: 0;
    opacity: .6;
    position: fixed;
    top: 0;
    transition: opacity .25s;
    width: 100%;
    z-index: var(--zIndex-modalMask)
}

body:not(.theme--dark) {
    --color-social-primary-facebook-background: #1877f2;
    --color-social-primary-google-background: #4285f4;
    --color-social-primary-apple-background: #000;
    --color-social-secondary-background: #fff;
    --color-social-secondary-border: #eee;
    --color-social-brand-background: #0f2d37
}

.theme--dark {
    --color-social-primary-facebook-background: #fff;
    --color-social-primary-google-background: #fff;
    --color-social-primary-apple-background: #fff;
    --color-social-secondary-background: #010a0f;
    --color-social-secondary-border: #555e61;
    --color-social-brand-background: #eee
}
