.premiumSquare {
    --zIndex-banner: 1;
    display: flex;
    overflow: hidden;
    padding: 0;
    position: relative
}

.premiumSquare:last-of-type .banx-premium_square_mobile:after {
    content: none
}

.event__header+.premiumSquare {
    border-top: 1px solid var(--color-combination-9)
}

.premiumSquare .bannerEnvelope {
    margin: 0 auto
}

.premiumSquare .banx-premium_square_mobile {
    margin-top: 0 !important;
    padding: 0;
    width: auto !important
}

.premiumSquare .banx-premium_square_mobile:after,
.premiumSquare .banx-premium_square_mobile:before {
    background-color: var(--color-combination-7);
    content: "";
    display: flex;
    height: 8px;
    width: 100vw
}

.noMatchFound+.premiumSquare .banx-premium_square_mobile:before {
    height: auto
}

.premiumSquare .banx-premium_square_mobile>div {
    align-items: center;
    background-color: var(--color-combination-9);
    display: flex;
    flex-direction: column;
    height: auto !important;
    justify-content: center
}

.pid_214 .premiumSquare .banx-premium_square_mobile>div,
.pid_8 .premiumSquare .banx-premium_square_mobile>div {
    flex-direction: column-reverse
}

.premiumSquare #lsadvert-premium_square_mobile {
    display: flex
}

.premiumSquare iframe[name=banx-premium_square_mobile] {
    height: 100vw !important;
    max-height: 480px;
    max-width: 480px;
    width: 100vw !important
}

.premiumSquare .ads__text {
    height: 16px;
    padding-top: 1px
}

.premiumSquare .ads__text,
.premiumSquare .adstext-small {
    color: var(--color-support-4)
}

.adsenvelope,
.non-responsive [class*=banx-responsive] {
    display: none
}

.adsenvelope.banx-fsnews_right_zone_1,
.adsenvelope.banx-fsnews_right_zone_1_detail,
.non-responsive [class*=banx-responsive].banx-fsnews_right_zone_1,
.non-responsive [class*=banx-responsive].banx-fsnews_right_zone_1_detail {
    display: block
}

.container .adsenvelope.banx-fsnews_right_zone_1,
.container .adsenvelope.banx-fsnews_right_zone_1_detail,
.container .non-responsive [class*=banx-responsive].banx-fsnews_right_zone_1,
.container .non-responsive [class*=banx-responsive].banx-fsnews_right_zone_1_detail {
    margin-bottom: 32px
}

.background-add-on,
body.branding {
    background-color: #00141e
}

.background-add-on>.header,
.background-add-on>.menuTop,
.background-add-on>.x24network,
body.branding>.header,
body.branding>.menuTop,
body.branding>.x24network {
    background-color: transparent
}

body.branding>.header {
    margin-top: 200px
}

body.seoTopWrapperHidden .adsbackground-wrapper {
    top: 27px !important
}

.background-add-off .adsbackground-wrapper {
    height: auto !important
}

.scrolling-banner-wrap {
    position: sticky;
    top: 24px
}

.banner--underContent .banx-content_bottom.adsenvelope,
.banner--underContent .banx-detail.adsenvelope,
.banner--underContent .banx-fsnews_content_bottom.adsenvelope,
.banner--underContent .banx-fsnews_content_bottom_detail.adsenvelope {
    max-width: var(--adsWidth);
    width: 100% !important
}

.pid_8 .banner--underContent .banx-content_bottom.adsenvelope,
.pid_8 .banner--underContent .banx-detail.adsenvelope,
.pid_8 .banner--underContent .banx-fsnews_content_bottom.adsenvelope,
.pid_8 .banner--underContent .banx-fsnews_content_bottom_detail.adsenvelope {
    padding-bottom: 6px;
    padding-top: 14px !important
}

.pid_8 .banner--underContent .banx-content_bottom.adsenvelope .ads__text,
.pid_8 .banner--underContent .banx-detail.adsenvelope .ads__text,
.pid_8 .banner--underContent .banx-fsnews_content_bottom.adsenvelope .ads__text,
.pid_8 .banner--underContent .banx-fsnews_content_bottom_detail.adsenvelope .ads__text {
    top: -14px
}

.banner--underContent .banx-content_bottom.adsenvelope>div,
.banner--underContent .banx-detail.adsenvelope>div,
.banner--underContent .banx-fsnews_content_bottom.adsenvelope>div,
.banner--underContent .banx-fsnews_content_bottom_detail.adsenvelope>div {
    height: auto !important
}

.banner--underContent .banx-content_bottom.adsenvelope .ads__text,
.banner--underContent .banx-detail.adsenvelope .ads__text,
.banner--underContent .banx-fsnews_content_bottom.adsenvelope .ads__text,
.banner--underContent .banx-fsnews_content_bottom_detail.adsenvelope .ads__text {
    position: absolute;
    width: 100px;
}

.banner--underContent .banx-content_bottom .adscontent,
.banner--underContent .banx-detail .adscontent,
.banner--underContent .banx-fsnews_content_bottom .adscontent,
.banner--underContent .banx-fsnews_content_bottom_detail .adscontent {
    overflow: hidden;
    padding-top: min(var(--adsHeight), 100%);
    position: relative;
    width: 100px;
}

.banner--underContent .banx-content_bottom .adscontent iframe,
.banner--underContent .banx-detail .adscontent iframe,
.banner--underContent .banx-fsnews_content_bottom .adscontent iframe,
.banner--underContent .banx-fsnews_content_bottom_detail .adscontent iframe {
    border: none;
    bottom: 0;
    height: 100% !important;
    left: 0;
    margin: 0 auto;
    max-width: var(--adsWidth);
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important
}

.fsNewsPage .banner--underContent {
    display: grid;
    grid-template: " underBanner      empty " 1fr/minmax(0, 1fr) auto;
    margin: 16px 0
}

@media only screen and (min-width:1047px) {
    .fsNewsPage .banner--underContent {
        grid-template: " . underBanner . empty . " 1fr/var(--width-spacer) minmax(0, 1fr) var(--width-separator) 300px var(--width-spacer);
        margin: 16px 0 28px
    }
}

.fsNewsPage .banner--underContent .adsenvelope {
    grid-area: underBanner;
    margin: 0 auto
}

@media only screen and (max-width:1047px) {
    .fsNewsPage .banner--underContent .adsenvelope[style*="width: 970px;"] {
        display: none !important
    }
}

.responsive.responsive [class*=banx-fsnews_responsive],
.responsive.responsive [class*=banx-responsive] {
    background: var(--color-support-5);
    border-top: 1px solid var(--color-support-1);
    bottom: 0;
    left: 0;
    padding: 0 0 10px !important;
    position: fixed;
    width: 100% !important;
    z-index: var(--zIndex-banner)
}

html[lang=da] .responsive.responsive [class*=banx-fsnews_responsive],
html[lang=da] .responsive.responsive [class*=banx-responsive] {
    padding: 10px 0 0 !important
}

html[lang=da] .responsive.responsive [class*=banx-fsnews_responsive] .adsgraphhori,
html[lang=da] .responsive.responsive [class*=banx-responsive] .adsgraphhori {
    margin-top: 2px
}

.responsive.responsive [class*=banx-fsnews_responsive]>div,
.responsive.responsive [class*=banx-responsive]>div {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 auto;
    position: relative
}

.responsive.responsive [class*=fixed_bottom_mobile]>div {
    width: 320px
}

.responsive.responsive [class*=fixed_bottom_tablet]>div {
    width: 728px
}

@media only screen and (max-width:1047px) {
    .adsbackground-wrapper {
        height: auto !important
    }
    .mobile_ad:not(.hide-box-over-content) {
        padding-bottom: 61px
    }
    .tablet_ad:not(.hide-box-over-content) {
        padding-bottom: 101px
    }
    [class*=banx-fsnews_top],
    [class*=banx-top] {
        display: none !important
    }
}

body.overflowHidden,
body.scrollLock.detailbody {
    overflow: hidden
}

@media only screen and (max-width:639px) {
    body.overflowHidden {
        overflow: initial
    }
    body.scrollLock {
        box-sizing: border-box;
        height: calc(var(--window-inner-height) - 1px);
        overflow: hidden
    }
    .scrollLock #ot-sdk-btn-floating,
    .scrollLock .advertisePageWrapper,
    .scrollLock .container__liveTableWrapper,
    .scrollLock .extraContent,
    .scrollLock .footerContainer,
    .scrollLock .livescoreWrapper,
    .scrollLock .selfPromo,
    .scrollLock [class*=banx-fsnews_responsive],
    .scrollLock [class*=banx-responsive] {
        display: none
    }
}

.bettingStrip {
    align-items: center;
    background-color: var(--color-highlight-2);
    border-radius: 8px;
    display: grid;
    grid-template-columns: minmax(150px, max-content) minmax(100px, max-content);
    grid-template-rows: max-content;
    justify-content: space-between;
    margin: 0 12px 12px;
    padding: 14px 20px
}

.bettingStrip__check {
    display: none
}

.bettingStrip__text {
    color: var(--color-combination-1);
    font-size: 16px;
    line-height: 22px;
    margin-right: 10px
}

.bettingStrip__text span {
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px
}

.bettingStrip__text span:after {
    content: "\00a0"
}

.bettingStrip__button {
    align-items: center;
    background-color: var(--color-primary);
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    margin-left: auto;
    padding: 10px 16px;
    text-align: center;
    text-transform: uppercase
}

@media only screen and (max-width:479px) {
    .bettingStrip {
        border-radius: 0;
        margin: 0 0 12px;
        padding: 12px
    }
}

.boxUnderContent {
    background-color: var(--color-combination-7);
    margin: -10px 0 0
}

.boxUnderContent .boxOverContent:not(.boxOverContent--active) {
    display: none !important
}

.boxUnderContent .boxOverContent {
    margin-bottom: 10px
}

.boxUnderContent.isSticky {
    bottom: 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: var(--zIndex-boxUnderContent)
}

.mobile_ad .boxUnderContent.isSticky,
.tablet_ad .boxUnderContent.isSticky {
    display: none !important
}

.boxUnderContent:empty {
    display: none
}

@media only screen and (max-width:799px) {
    .boxUnderContent {
        margin: 0;
        padding: 0
    }
    .boxUnderContent.isSticky {
        position: static
    }
    .mobile_ad .boxUnderContent.isSticky,
    .tablet_ad .boxUnderContent.isSticky {
        display: block !important
    }
    .boxUnderContent.isMobileSticky {
        position: static
    }
    .mobile_ad .boxUnderContent.isMobileSticky,
    .tablet_ad .boxUnderContent.isMobileSticky {
        display: none !important
    }
    .boxUnderContent .boxOverContent {
        margin: 10px 0 0
    }
}

.boxOverContent {
    background-color: var(--color-boxOverContent-1);
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 12px;
    user-select: none
}

.boxOverContent:not(.boxOverContent--active) {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    background: var(--color-skeleton-1);
    background-image: linear-gradient(to right, var(--color-skeleton-1) 0, var(--color-skeleton-2) 20%, var(--color-skeleton-1) 40%, var(--color-skeleton-1) 100%);
    background-repeat: repeat-y;
    background-size: 800px 1px
}

.boxOverContent:not(.boxOverContent--active) .boxOverContent__image {
    display: none
}

.boxOverContent--detail {
    bottom: 0;
    margin: 0 auto;
    max-width: 660px;
    position: sticky;
    position: -webkit-sticky;
    z-index: var(--zIndex-boxOverContent)
}

.boxOverContent--detail .boxOverContent__banner,
.boxOverContent--detailModern .boxOverContent__banner {
    border-bottom: none !important;
    margin-bottom: 0
}

.boxOverContent--active .boxOverContent__banner,
.boxOverContent.enabledSkeleton .boxOverContent__banner {
    display: grid
}

.boxOverContent--active .boxOverContent__banner+.boxOverContent__getOffer,
.boxOverContent--active .boxOverContent__banner>* {
    visibility: visible
}

.detail-experimental.mobile_ad .boxOverContent,
.detail-experimental.tablet_ad .boxOverContent,
.mobile_ad .boxOverContent--detail,
.mobile_ad .boxOverContent--detailModern,
.tablet_ad .boxOverContent--detail,
.tablet_ad .boxOverContent--detailModern {
    display: none !important
}

.boxOverContent--detailModern {
    bottom: 0;
    padding-top: 10px;
    position: sticky;
    position: -webkit-sticky;
    z-index: var(--zIndex-boxOverContent)
}

.boxOverContent--detailModern:last-child div {
    border-radius: 4px 4px 0 0
}

.boxOverContent--detailModern .boxOverContent {
    margin-bottom: 0
}

.boxOverContent--split {
    column-gap: 8px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    position: relative
}

.boxOverContent--split:before {
    background-color: var(--color-combination-7);
    content: "";
    height: calc(100% - 12px);
    position: absolute;
    width: 100%;
    z-index: -1
}

.boxOverContent--split.isSticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: var(--zIndex-boxOverContent)
}

.boxOverContent--split .boxOverContent {
    align-content: flex-start;
    margin-bottom: 12px
}

.boxOverContent--split .boxOverContent__banner {
    grid-template: " .  .    .    icon icon " 12px " .  logo .    icon icon " minmax(30px, auto) " .  .    .    .    .    " 6px " .  text text text .    " minmax(16px, auto) " .  tac  tac  tac  .    " max-content " .  .    .    .    .    " 12px /20px auto 1fr 12px 20px
}

.boxOverContent--split .boxOverContent__image {
    width: 100px;
}

.boxOverContent--split .boxOverContent__text {
    font-size: 16px;
    line-height: 1
}

.boxOverContent--split .boxOverContent__tac {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    max-height: 60px;
    overflow: hidden
}

.boxOverContent--a,
.boxOverContent--b {
    background-color: var(--color-combination-7)
}

.boxOverContent--a.isSticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: var(--zIndex-boxOverContent)
}

.boxOverContent--type-2,
.boxOverContent--type-5 {
    display: none
}

.boxOverContent--type-2.enabledSkeleton,
.boxOverContent--type-2.isNotClosed,
.boxOverContent--type-5.enabledSkeleton,
.boxOverContent--type-5.isNotClosed {
    display: grid
}

.boxOverContent--type-2.disabledSkeleton,
.boxOverContent--type-5.disabledSkeleton {
    display: none
}

.boxOverContent--type-2.boxOverContent--active,
.boxOverContent--type-5.boxOverContent--active {
    display: grid
}

.boxOverContent--type-2 .boxOverContent__image {
    margin-left: -5px
}

.boxOverContent--type-5:not(.boxOverContent--active) .boxOverContent__banner {
    aspect-ratio: 10/1.221;
    width: 100px;
}

.boxOverContent--type-5 .boxOverContent__banner {
    grid-template: "logo"
}

.boxOverContent--type-5 .boxOverContent__image {
    border-radius: 8px;
    width: 100px;
}

.boxOverContent--type-5 .boxOverContent__close {
    background-color: rgba(0, 20, 30, .25);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M0 11.405 5.405 6 0 .595.594 0 6 5.405 11.406 0 12 .595 6.595 6 12 11.405l-.594.595L6 6.595.594 12z'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 4px;
    height: 24px;
    opacity: 1;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px
}

.boxOverContent--type-5 .boxOverContent__close:hover {
    background-color: rgba(0, 20, 30, .5)
}

.boxOverContent--standings {
    margin: 10px auto;
    max-width: 660px
}

.boxOverContent--backup .boxOverContent__banner {
    grid-template: " .    .    .    .    . " 10px " .    logo .    text . " max-content " .    logo .    tac  . " max-content " .    .    .    .    . " 10px/10px max-content 10px 1fr 10px
}

.boxOverContent--backup .boxOverContent__tac {
    text-align: left
}

.boxOverContent__banner {
    color: var(--color-boxOverContent-2);
    display: none;
    grid-template: " .    .            .        .    .    icon   icon " 12px " .    logo         .        text .    icon   icon " minmax(30px, auto) " .    tac          tac      tac  tac  tac    .    " max-content " .    .            .        .    .    .      .    " 12px /20px max-content 12px auto 1fr max-content 20px;
    position: relative
}

.boxOverContent__banner+.boxOverContent__getOffer,
.boxOverContent__banner>* {
    visibility: hidden
}

.boxOverContent__bannerLink {
    height: 100%;
    position: absolute;
    width: 100px;
}

.boxOverContent__image {
    grid-area: logo
}

.boxOverContent__text {
    align-self: center;
    font-size: 18px;
    font-weight: 700;
    grid-area: text;
    line-height: .78
}

.boxOverContent__text span {
    color: var(--color-boxOverContent-3)
}

.boxOverContent__tac {
    font-size: 12px;
    grid-area: tac;
    line-height: 1;
    margin-top: 6px;
    opacity: .75
}

.boxOverContent__notice {
    font-size: 12px;
    opacity: .5;
    position: absolute;
    right: 5px;
    top: 1px
}

.boxOverContent__close {
    align-items: center;
    display: flex;
    grid-area: icon;
    height: 40px;
    justify-content: center;
    opacity: .5;
    text-decoration: none;
    transition: .25s;
    width: 40px;
    z-index: 1
}

.boxOverContent__close--hideOnDesktop {
    display: none
}

.boxOverContent__close:hover {
    opacity: 1;
    text-decoration: none
}

.boxOverContent__svg {
    color: var(--color-boxOverContent-2);
    height: 12px;
    width: 20px
}

.boxOverContent__arrow,
.boxOverContent__getOffer {
    display: none
}

.boxOverContent__getOfferLink {
    align-items: center;
    background-color: var(--color-boxOverContent-5);
    border-radius: 8px;
    color: var(--color-boxOverContent-4);
    display: flex;
    flex: 1;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    justify-content: center;
    margin: 0 10px 10px;
    text-align: center;
    text-decoration: none
}

.boxOverContent__getOfferLink:hover {
    text-decoration: none
}

@media only screen and (max-width:799px) {
    body:not(.detailbody) .boxOverContent--a,
    body:not(.detailbody) .boxOverContent--b {
        margin: 0
    }
    body:not(.detailbody) .boxOverContent:not(.boxOverContent--active) .boxOverContent__banner.isExpanded {
        overflow: hidden
    }
    body:not(.detailbody) .boxOverContent--type-2:not(.boxOverContent--active) .boxOverContent__banner.isExpanded {
        height: 65px
    }
}

@media only screen and (max-width:639px) {
    body:not(.detailbody) .boxOverContent {
        border-radius: 4px;
        margin: var(--margin-boxOverContent, 5px 5px 0)
    }
    body:not(.detailbody) .boxOverContent__banner {
        grid-template: ".    .           .    .    icon icon " 14px ".    logo        .    text icon icon " minmax(23px, max-content) ".    tac         tac  tac  tac  .    " max-content ".    .           .    .    .    .    " 12px /12px max-content 12px 1fr 20px 12px
    }
    body:not(.detailbody) .boxOverContent__banner.isExpanded {
        border-bottom: none
    }
    body:not(.detailbody) .boxOverContent__banner.isExpanded .boxOverContent__tac {
        overflow: var(--overflow-boxOverContentTac, initial);
        text-overflow: clip;
        white-space: normal
    }
    body:not(.detailbody) .boxOverContent__banner.isExpanded .boxOverContent__svg {
        transform: rotate(180deg)
    }
    body:not(.detailbody) .boxOverContent__banner.isExpanded+.boxOverContent__getOffer {
        display: flex
    }
    body:not(.detailbody) .boxOverContent--split {
        --width-boxOverContentSplit: 100%;
        column-gap: 5px;
        margin-bottom: 0
    }
    body:not(.detailbody) .boxOverContent--split:before {
        height: 100px;
    }
    body:not(.detailbody) .boxOverContent--split.isSticky {
        position: relative;
        top: auto
    }
    body:not(.detailbody) .boxOverContent--split.isMobileSticky {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: var(--zIndex-boxOverContent)
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent {
        --margin-boxOverContent: 5px 0 0;
        align-content: space-between
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent:first-of-type {
        --margin-boxOverContent: 5px 0 0 5px
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent:last-of-type {
        --margin-boxOverContent: 5px 5px 0 0
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent__svg {
        width: 12px
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent__banner {
        grid-template: " .   .    .    icon icon " 10px " .   logo .    icon icon " minmax(23px, auto) " .   .    .    icon icon " 6px " .   text text text .    " minmax(16px, auto) " .   tac  tac  tac  .    " max-content " .   .    .    .    .    " 8px /8px minmax(50px, 84px) 1fr 18px 8px
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent__banner:not(.isExpanded) .boxOverContent__text {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent__banner:not(.isExpanded) .boxOverContent__tac {
        display: none
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent__tac {
        -webkit-line-clamp: 3;
        --overflow-boxOverContentTac: hidden;
        max-height: 36px
    }
    body:not(.detailbody) .boxOverContent--split .boxOverContent__getOfferLink {
        margin: 0 8px 8px
    }
    body:not(.detailbody) .boxOverContent--type-5 .boxOverContent__banner {
        grid-template: none
    }
    body:not(.detailbody) .boxOverContent--type-5 .boxOverContent__banner:after {
        content: none
    }
    body:not(.detailbody) .boxOverContent--type-5 .boxOverContent__bannerLink {
        display: block
    }
    body:not(.detailbody) .boxOverContent--type-5 .boxOverContent__image {
        border-radius: 4px;
        width: 100px;
    }
    body:not(.detailbody) .boxOverContent--type-5 .boxOverContent__close {
        width: 24px
    }
    body:not(.detailbody) .boxOverContent--a.isSticky {
        position: relative;
        top: auto
    }
    body:not(.detailbody) .boxOverContent--a.isMobileSticky {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: var(--zIndex-boxOverContent)
    }
    body:not(.detailbody) .boxOverContent__bannerLink {
        display: flex
    }
    body:not(.detailbody) .boxOverContent__image {
        width: var(--width-boxOverContentSplit, var(--width-boxOverContent-logo))
    }
    body:not(.detailbody) .boxOverContent__text {
        font-size: 12px;
        line-height: 14px;
        text-decoration: none
    }
    body:not(.detailbody) .boxOverContent__notice {
        font-size: 10px;
        right: 4px;
        top: 1px
    }
    body:not(.detailbody) .boxOverContent__tac {
        font-size: 10px;
        line-height: 12px;
        margin-top: 5px;
        overflow: hidden;
        text-align: initial;
        text-overflow: ellipsis;
        white-space: nowrap
    }
    body:not(.detailbody) .boxOverContent__close {
        width: 32px
    }
    body:not(.detailbody) .boxOverContent__close~.boxOverContent__arrow {
        display: none
    }
    body:not(.detailbody) .boxOverContent__close--hideOnDesktop {
        display: flex
    }
    body:not(.detailbody) .boxOverContent__close--hideOnMobile {
        display: none
    }
    body:not(.detailbody) .boxOverContent__close--hideOnMobile~.boxOverContent__arrow {
        display: flex
    }
    body:not(.detailbody) .boxOverContent__arrow {
        align-items: center;
        display: flex;
        grid-area: icon;
        justify-content: center;
        opacity: .7;
        position: relative;
        z-index: var(--zIndex-banner)
    }
    body:not(.detailbody) .boxOverContent__svg {
        transform: rotate(0deg);
        transition: .25s
    }
}

@media only screen and (max-width:479px) {
    .detailbody .boxOverContent__banner {
        grid-template: ".    .           .    .    icon icon " 14px ".    logo        .    text icon icon " minmax(23px, max-content) ".    tac         tac  tac  tac  .    " max-content ".    .           .    .    .    .    " 12px /12px max-content 12px 1fr 20px 12px
    }
    .detailbody .boxOverContent__banner.isExpanded {
        border-bottom: none
    }
    .detailbody .boxOverContent__banner.isExpanded .boxOverContent__tac {
        overflow: var(--overflow-boxOverContentTac, initial);
        text-overflow: clip;
        white-space: normal
    }
    .detailbody .boxOverContent__banner.isExpanded .boxOverContent__svg {
        transform: rotate(180deg)
    }
    .detailbody .boxOverContent__banner.isExpanded+.boxOverContent__getOffer {
        display: flex
    }
    .detailbody .boxOverContent--split {
        --width-boxOverContentSplit: 100%;
        column-gap: 5px;
        margin-bottom: 0
    }
    .detailbody .boxOverContent--split:before {
        height: 100px;
    }
    .detailbody .boxOverContent--split.isSticky {
        position: relative;
        top: auto
    }
    .detailbody .boxOverContent--split.isMobileSticky {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: var(--zIndex-boxOverContent)
    }
    .detailbody .boxOverContent--split .boxOverContent {
        --margin-boxOverContent: 5px 0 0;
        align-content: space-between
    }
    .detailbody .boxOverContent--split .boxOverContent:first-of-type {
        --margin-boxOverContent: 5px 0 0 5px
    }
    .detailbody .boxOverContent--split .boxOverContent:last-of-type {
        --margin-boxOverContent: 5px 5px 0 0
    }
    .detailbody .boxOverContent--split .boxOverContent__svg {
        width: 12px
    }
    .detailbody .boxOverContent--split .boxOverContent__banner {
        grid-template: " .   .    .    icon icon " 10px " .   logo .    icon icon " minmax(23px, auto) " .   .    .    icon icon " 6px " .   text text text .    " minmax(16px, auto) " .   tac  tac  tac  .    " max-content " .   .    .    .    .    " 8px /8px minmax(50px, 84px) 1fr 18px 8px
    }
    .detailbody .boxOverContent--split .boxOverContent__banner:not(.isExpanded) .boxOverContent__text {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden
    }
    .detailbody .boxOverContent--split .boxOverContent__banner:not(.isExpanded) .boxOverContent__tac {
        display: none
    }
    .detailbody .boxOverContent--split .boxOverContent__tac {
        -webkit-line-clamp: 3;
        --overflow-boxOverContentTac: hidden;
        max-height: 36px
    }
    .detailbody .boxOverContent--split .boxOverContent__getOfferLink {
        margin: 0 8px 8px
    }
    .detailbody .boxOverContent--type-5 .boxOverContent__banner {
        grid-template: none
    }
    .detailbody .boxOverContent--type-5 .boxOverContent__banner:after {
        content: none
    }
    .detailbody .boxOverContent--type-5 .boxOverContent__bannerLink {
        display: block
    }
    .detailbody .boxOverContent--type-5 .boxOverContent__image {
        border-radius: 4px;
        width: 100px;
    }
    .detailbody .boxOverContent--type-5 .boxOverContent__close {
        width: 24px
    }
    .detailbody .boxOverContent--a.isSticky {
        position: relative;
        top: auto
    }
    .detailbody .boxOverContent--a.isMobileSticky {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: var(--zIndex-boxOverContent)
    }
    .detailbody .boxOverContent__bannerLink {
        display: flex
    }
    .detailbody .boxOverContent__image {
        width: var(--width-boxOverContentSplit, var(--width-boxOverContent-logo))
    }
    .detailbody .boxOverContent__text {
        font-size: 12px;
        line-height: 14px;
        text-decoration: none
    }
    .detailbody .boxOverContent__notice {
        font-size: 10px;
        right: 4px;
        top: 1px
    }
    .detailbody .boxOverContent__tac {
        font-size: 10px;
        line-height: 12px;
        margin-top: 5px;
        overflow: hidden;
        text-align: initial;
        text-overflow: ellipsis;
        white-space: nowrap
    }
    .detailbody .boxOverContent__close {
        width: 32px
    }
    .detailbody .boxOverContent__close~.boxOverContent__arrow {
        display: none
    }
    .detailbody .boxOverContent__close--hideOnDesktop {
        display: flex
    }
    .detailbody .boxOverContent__close--hideOnMobile {
        display: none
    }
    .detailbody .boxOverContent__close--hideOnMobile~.boxOverContent__arrow {
        display: flex
    }
    .detailbody .boxOverContent__arrow {
        align-items: center;
        display: flex;
        grid-area: icon;
        justify-content: center;
        opacity: .7;
        position: relative;
        z-index: var(--zIndex-banner)
    }
    .detailbody .boxOverContent__svg {
        transform: rotate(0deg);
        transition: .25s
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0
    }
    to {
        background-position: 468px 0
    }
}

.lac__box {
    align-items: center;
    background-color: var(--color-highlight);
    border-radius: 8px;
    color: var(--color-combination-1);
    display: grid;
    grid-template: " text  text text " max-content " text2 .    button " max-content/1fr 10px max-content;
    margin-bottom: 10px;
    padding: 16px;
    position: relative;
    user-select: none
}

@media only screen and (max-width:639px) {
    .lac__box {
        grid-template: " text   " max-content " text2  " max-content " button " max-content/1fr;
        margin: 20px 10px;
        top: 10px
    }
}

.lac__icon {
    display: none
}

.lac__text {
    font-size: 18px;
    font-weight: 700;
    grid-area: text
}

@media only screen and (max-width:639px) {
    .lac__text {
        font-size: 16px
    }
}

.lac__description {
    font-size: 13px;
    grid-area: text2;
    letter-spacing: .4px;
    margin-top: 4px
}

.lac__button {
    background-color: var(--color-secondary-1);
    border-radius: 8px;
    color: var(--color-white);
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    grid-area: button;
    letter-spacing: .4px;
    line-height: 1;
    margin-right: 15px;
    padding: 8px 12px;
    text-align: center;
    text-transform: uppercase
}

.lac__button:hover {
    opacity: .8
}

@media only screen and (max-width:639px) {
    .lac__button {
        margin-top: 8px;
        padding: 6px 10px
    }
}

.lacModal__paragraph {
    border-top: 1px solid var(--color-support-1);
    color: var(--color-support-4);
    font-size: 13px;
    line-height: 1.38;
    margin: 0 -20px 16px;
    padding: 16px 20px 0;
    text-align: center
}

.lacModal__question {
    color: var(--color-combination-1);
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 20px;
    text-align: center
}

.lacModal__buttons {
    display: flex;
    justify-content: center
}

.lacModal__buttons>* {
    margin: 0 8px
}

.lacModal__confirmationButton {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: var(--color-white);
    border: 1px solid var(--color-secondary-3);
    border-radius: 8px;
    color: var(--color-secondary-3);
    cursor: pointer;
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    outline: 0;
    padding: 11px 24px;
    text-transform: uppercase;
    width: max-content
}

.lacModal__confirmationButton:hover {
    opacity: .8
}

.lacModal__confirmationButtonActive {
    background: var(--color-secondary-3);
    color: var(--color-white)
}

.spainAgeConfirmation {
    --legalAge-combiantion-1: #ff0046;
    --legalAge-combiantion-2: transparent;
    margin-bottom: 16px
}

.theme--dark .spainAgeConfirmation {
    --legalAge-combiantion-1: transparent;
    --legalAge-combiantion-2: #001e28
}

@media only screen and (max-width:639px) {
    .spainAgeConfirmation {
        background-color: var(--color-combination-7);
        margin-bottom: 0;
        padding: 8px
    }
}

.spainAgeConfirmation__wrapper {
    background-color: #fff;
    border: 2px solid var(--legalAge-combiantion-1);
    border-radius: 8px;
    display: grid;
    grid-template: " heading " " .       " 4px " text    " " .       " 8px " buttons "/1fr;
    padding: 12px 18px 14px 14px
}

@media only screen and (max-width:639px) {
    .spainAgeConfirmation__wrapper {
        grid-template: " heading " " .       " 4px " text    " " .       " 12px " buttons "/1fr;
        padding: 14px
    }
}

.spainAgeConfirmation__heading {
    color: #001e28;
    font-size: 22px;
    font-weight: 700;
    grid-area: heading
}

@media only screen and (max-width:639px) {
    .spainAgeConfirmation__heading {
        font-size: 18px
    }
}

.spainAgeConfirmation__text {
    color: var(--color-combination-32);
    font-size: 12px;
    grid-area: text;
    letter-spacing: .4px;
    line-height: 16px;
    margin: initial
}

.spainAgeConfirmation__buttons {
    grid-gap: 12px;
    align-items: center;
    display: grid;
    grid-area: buttons;
    grid-template-columns: max-content max-content;
    justify-content: end
}

@media only screen and (max-width:639px) {
    .spainAgeConfirmation__buttons {
        justify-content: start
    }
}

.spainAgeConfirmation__button {
    border-radius: 8px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: .4px;
    text-align: center
}

@media only screen and (max-width:359px) {
    .spainAgeConfirmation__button {
        font-size: 10px
    }
}

.spainAgeConfirmation__button--confirm {
    background-color: var(--legalAge-combiantion-1);
    border: 1px solid var(--legalAge-combiantion-2);
    color: var(--color-combination-11);
    padding: 8px 12px
}

.spainAgeConfirmation__button--confirm:hover {
    opacity: .8
}

.spainAgeConfirmation__button--decline {
    background-color: initial;
    border: initial;
    color: var(--color-combination-31);
    text-decoration: underline
}

.spainAgeConfirmation__button--decline:hover {
    text-decoration: none
}