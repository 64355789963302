.registrationPrompt {
    background-color: var(--color-combination-11);
    display: grid;
    grid-template: " image image   image " auto " .     content .     " auto/1fr 368px 1fr
}



.registrationPrompt__imageWrapper {
    display: flex;
    grid-area: image;
    justify-content: center;
    margin-bottom: 16px
}

.registrationPrompt__imageWrapper img {
    overflow: hidden
}

.registrationPrompt__content {
    display: grid;
    grid-area: content;
    justify-content: center;
    margin-bottom: 56px
}


.registrationPrompt__title {
    color: var(--color-combination-1);
    font-size: 22px;
    font-weight: 700;
    text-align: center
}

.registrationPrompt__subTitle {
    color: var(--color-combination-5);
    font-size: 13px;
    margin-top: 12px;
    text-align: center
}

.registrationPrompt__buttonsWrapper {
    display: grid;
    justify-self: center;
    margin: 32px 0 0;
    row-gap: 12px;
    width: 320px
}


.registrationPrompt__legal {
    color: var(--color-support-4);
    font-size: 11px;
    line-height: 15px;
    margin-top: 12px;
    text-align: center
}

.registrationPrompt__legal a {
    color: var(--color-support-4);
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline
}

.registrationPrompt__legal a:hover {
    text-decoration: none
}

.langBoxSetup {
    --content-width: 320px;
    color: var(--color-combination-1);
    max-height: 635px;
    overflow-y: hidden;
    width: 435px
}



.langBoxSetup--moreLangs {
    max-height: unset
}
