.lineup {
    padding: 0 12px;
    width: 100px;
}

.lineup--wrapper {
    background-color: var(--color-combination-9);
    border-radius: 8px;
    margin: 12px 0;
    padding: 16px 0
}

.lineup--soccer {
    --gridTemplateAreas-lineup: " jersey player age matchesPlayed goal yellowCard redCard . ";
    --gridTemplateColumns-lineup: minmax(60px, 78px) minmax(140px, 1fr) 48px repeat(4, minmax(40px, 48px)) minmax(4px, 16px)
}

.lineup--hockey {
    --gridTemplateAreas-lineup: " jersey player age matchesPlayed goalsOrSavesPercentage assistsOrGoalsAgainstAverage pointsOrShoutouts . ";
    --gridTemplateColumns-lineup: minmax(60px, 78px) minmax(140px, 1fr) 48px repeat(4, minmax(40px, 48px)) minmax(4px, 16px)
}

.lineup--basketball {
    --gridTemplateAreas-lineup: " jersey player age . ";
    --gridTemplateColumns-lineup: minmax(60px, 78px) minmax(140px, 1fr) 48px minmax(4px, 16px)
}

.lineup__header {
    background-color: var(--color-support-1);
    border-radius: 4px;
    color: var(--color-support-4);
    display: grid;
    font-size: 11px;
    font-weight: 700;
    grid-template-areas: var(--gridTemplateAreas-lineup);
    grid-template-columns: var(--gridTemplateColumns-lineup);
    grid-template-rows: 28px;
    letter-spacing: .4px;
    text-transform: uppercase
}

.lineup__header svg {
    height: 14px;
    width: 14px
}

.lineup__title {
    color: var(--color-combination-2);
    display: flex;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin: 16px 0 12px
}

.lineup__filter {
    --filterGroup-padding: 0 12px 16px;
    --filter-borderBottom: 1px solid var(--color-support-1)
}

.lineup__row {
    border-radius: 8px;
    color: var(--color-combination-2);
    display: grid;
    font-size: 13px;
    grid-template-areas: var(--gridTemplateAreas-lineup);
    grid-template-columns: var(--gridTemplateColumns-lineup);
    grid-template-rows: 48px
}

.lineup__row:nth-child(2n) {
    background-color: rgba(var(--color-support-1-rgb), .4)
}

.lineup__cell {
    align-items: center;
    display: flex;
    justify-content: center
}

.lineup__cell--jersey {
    font-weight: 700;
    grid-area: jersey
}

.lineup__cell--player,
.lineup__cell--playerHeader {
    grid-area: player;
    justify-content: start
}

.lineup__cell--player {
    align-items: center;
    display: grid;
    grid-template: " flag .    nameAndAbsence" auto/18px 10px 1fr
}

.lineup__cell--flag {
    grid-area: flag;
    height: 12px
}

.lineup__cell--nameAndAbsence {
    align-items: center;
    column-gap: 8px;
    display: flex;
    grid-area: nameAndAbsence;
    overflow: hidden;
    white-space: nowrap
}

.lineup__cell--name {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis
}

.lineup__cell--name.lineup__cell--name {
    color: var(--color-combination-2);
    display: block
}

.lineup__cell--name:hover {
    text-decoration: underline
}

.lineup__cell--absence {
    color: var(--color-red);
    flex-shrink: 0;
    height: 12px;
    width: 12px
}

.lineup__cell--age {
    color: var(--color-support-4);
    grid-area: age
}

.lineup__cell--matchesPlayed {
    grid-area: matchesPlayed
}

.lineup__cell--goal {
    grid-area: goal
}

.lineup__cell--yellowCard {
    grid-area: yellowCard
}

.lineup__cell--yellowCard svg {
    color: var(--color-yellow)
}

.lineup__cell--redCard {
    grid-area: redCard
}

.lineup__cell--redCard svg {
    color: var(--color-red)
}

.lineup__cell--goals,
.lineup__cell--savesPercentage {
    grid-area: goalsOrSavesPercentage
}

.lineup__cell--assists,
.lineup__cell--goalsAgainstAverage {
    grid-area: assistsOrGoalsAgainstAverage
}

.lineup__cell--points,
.lineup__cell--shutouts {
    grid-area: pointsOrShoutouts
}

.lineup__cell--gray {
    color: var(--color-support-4)
}

@media only screen and (max-width:479px) {
    .lineup {
        padding: 0
    }
    .lineup--soccer {
        --gridTemplateColumns-lineup: minmax(32px, 48px) minmax(140px, 1fr) 32px repeat(4, minmax(24px, 32px)) 4px
    }
    .lineup--hockey {
        --gridTemplateColumns-lineup: minmax(32px, 48px) minmax(140px, 1fr) repeat(5, minmax(24px, 32px)) 4px
    }
    .lineup--hockey .lineup__rows--goalkeepers {
        --gridTemplateColumns-lineup: minmax(32px, 48px) minmax(140px, 1fr) repeat(2, minmax(24px, 32px)) repeat(2, minmax(32px, 40px)) minmax(24px, 32px) 4px
    }
    .lineup--basketball {
        --gridTemplateColumns-lineup: 48px minmax(140px, 1fr) 48px 4px
    }
    .lineup__header,
    .lineup__row {
        border-radius: initial
    }
    .lineup__title {
        margin-left: 12px
    }
}

.archive#tournament-page-archiv {
    background-color: var(--color-support-5);
    border-radius: 8px;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: minmax(48px, auto);
    grid-template-rows: 28px;
    margin: 12px 0;
    padding: 20px 12px
}

.archive__header {
    background-color: var(--color-support-1);
    border-radius: 4px;
    color: var(--color-support-4);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: .4px;
    text-transform: uppercase
}

.archive__header,
.archive__row {
    align-items: center;
    column-gap: 4px;
    display: grid;
    grid-template-areas: " season winner ";
    grid-template-columns: 1fr 1fr
}

.archive__row {
    border-radius: 8px;
    padding: 8px 0
}

.archive__row:nth-of-type(odd) {
    background-color: rgba(var(--color-support-1-rgb), .4)
}

.archive__season {
    display: flex;
    grid-area: season;
    padding-left: 12px
}

.archive__logo {
    background: no-repeat 50%/16px 16px;
    flex: 0 0 16px;
    height: 16px;
    margin-right: 8px
}

.archive__winner {
    font-weight: 700;
    grid-area: winner;
    padding-left: 12px;
    padding-right: 12px
}

.archive__winnerBlock {
    align-items: center;
    display: flex;
    padding-bottom: 4px;
    padding-top: 4px
}

.archive__text {
    color: var(--color-combination-2)
}

.archive__text--clickable:hover {
    text-decoration-line: underline
}

@media only screen and (max-width:639px) {
    .archive__header {
        border-radius: initial
    }
    .archive__row {
        margin: 0 4px
    }
    .archive#tournament-page-archiv {
        border-radius: initial;
        padding: 16px 0
    }
}

.upcomingDraw {
    color: var(--color-support-4);
    font-weight: 700;
    padding: 16px 20px 24px
}

.upcomingDraw__icon,
.upcomingDraw__row {
    align-items: center;
    display: flex
}

.upcomingDraw__icon {
    margin-left: 8px;
    order: 1
}

.upcomingDraw__throphy {
    height: 13px;
    width: 12px
}

.upcomingDraw__name {
    white-space: nowrap;
    width: min-content
}

@media screen and (max-width:639px) {
    .upcomingDraw {
        padding: 12px 12px 24px
    }
}